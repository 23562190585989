import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

export const MOUNT_SELECTOR = '.vue-award-mount'

/* TIME_DEATH_DAY - время, на которое должен исчезнуть баннер после закрытия */
export const TIME_DEATH_DAY = 1 / 2 // половина дня, или 12 часов

export const IMAGE_AWARD_PREMIUM = {
  src: '/static/_v1/pd/pictures/award/award.jpg',
  alt: 'Премия ПроДокторов',
}

export const IMAGE_AWARD_GRAND_PRIX = {
  src: '/static/_v1/pd/pictures/award/grand-prix.jpg',
  alt: 'Гран-при премии ПроДокторов',
}

const GRAND_PRIX_TRANSLATION_HREF = 'https://vk.com/video-217078578_456239384'

const EVENT_YEAR = 2024

export const EVENT_DATES = {
  awardAnnounce: new Date(EVENT_YEAR, 9, 2), // 2 окт. - анонс премии
  awardResults: new Date(EVENT_YEAR, 10, 2), // 2 ноя. - результаты премии
  grandRegistration: new Date(EVENT_YEAR, 10, 11), // 11 ноя. - регистрация на Гран-при (появление ссылки на трансляцию)
  grandTranslation: new Date(EVENT_YEAR, 10, 16, 19, 0, 0), // 16 ноя. 19:00 - трансляция Гран-при на YouTube
  grandResults: new Date(EVENT_YEAR, 10, 17), // 17 ноя. - результаты Гран-при
  showOver: new Date(EVENT_YEAR, 11, 1), // 1 дек. - плашка скрывается
}

export const EVENT_DATE_TEXTS = {
  awardResults: format(EVENT_DATES.awardResults, 'd MMMM', { locale: ru }), // 1 ноября - результаты премии
  grandTranslation: format(EVENT_DATES.grandTranslation, 'd MMMM', { locale: ru }), // 16 ноября 19:00- трансляция Гран-при на YouTube
}

export const CONTENT_BY_DATE = {
  [EVENT_DATES.awardAnnounce.toISOString()]: {
    isDarkTheme: false,
    banner: {
      title: `Всероссийская премия ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Премия ПроДокторов ${EVENT_YEAR}`,
      image: IMAGE_AWARD_PREMIUM,
      events: [
        {
          isIconCheckCircle: false,
          title: 'Всероссийская премия ПроДокторов',
          date: `${EVENT_DATE_TEXTS.awardResults}`,
          paragraphs: [
            'Определим звёзд медицины за этот год. Назовём лучших врачей и лучшие клиники по отзывам пациентов в каждом регионе.',
          ],
        },
        {
          isIconCheckCircle: false,
          title: 'Гран-при премии ПроДокторов',
          date: EVENT_DATE_TEXTS.grandTranslation,
          paragraphs: [
            `Определим номинантов на звание лучших врачей всей страны и лучших клиник каждого округа,
             Москвы, Санкт-Петербурга и Республики Крым.
             Встречаемся на Гран-при премии ПроДокторов, чтобы назвать победителей и вручить награды.`,
          ],
        },
      ],
      button: {
        text: 'На сайт премии',
        href: 'https://award.prodoctorov.ru/',
      },
    },
  },

  [EVENT_DATES.awardResults.toISOString()]: {
    isDarkTheme: false,
    banner: {
      title: `Итоги Всероссийской премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Премия ПроДокторов ${EVENT_YEAR}`,
      image: IMAGE_AWARD_PREMIUM,
      events: [
        {
          isIconCheckCircle: true,
          title: 'Всероссийская премия ПроДокторов',
          date: `${EVENT_DATE_TEXTS.awardResults}`,
          paragraphs: [
            'Назвали лучших врачей и лучшие клиники по\u00A0отзывам пациентов в каждом регионе.',
          ],
        },
        {
          isIconCheckCircle: false,
          title: 'Гран-при премии ПроДокторов',
          date: EVENT_DATE_TEXTS.grandTranslation,
          paragraphs: [
            `Определим номинантов на звание лучших врачей всей страны и лучших клиник каждого округа,
             Москвы, Санкт-Петербурга и Республики Крым.
             Встречаемся на Гран-при премии ПроДокторов, чтобы назвать победителей и вручить награды.`,
          ],
        },
      ],
      button: {
        text: 'Смотреть результаты',
        href: `https://award.prodoctorov.ru/awards/${EVENT_YEAR}/`,
      },
    },
  },

  [EVENT_DATES.grandRegistration.toISOString()]: {
    isDarkTheme: true,
    banner: {
      title: `Гран-при премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Гран-при премии ${EVENT_YEAR}`,
      image: IMAGE_AWARD_GRAND_PRIX,
      events: [
        {
          isIconCheckCircle: false,
          title: '',
          date: `${EVENT_DATE_TEXTS.grandTranslation}, 19:00 Мск`,
          paragraphs: [
            `Определим номинантов на звание лучших врачей всей страны и лучших клиник каждого округа,
             Москвы, Санкт-Петербурга и Республики Крым.
             Встречаемся на Гран-при премии ПроДокторов, чтобы назвать победителей и вручить награды.`,
            'Ставьте напоминание, чтобы\u00A0первыми узнать имена победителей.',
          ],
        },
      ],
      button: {
        text: 'Установить напоминание',
        href: GRAND_PRIX_TRANSLATION_HREF,
      },
    },
  },

  [EVENT_DATES.grandTranslation.toISOString()]: {
    isDarkTheme: true,
    banner: {
      title: `Прямая трансляция Гран-при премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Гран-при премии ${EVENT_YEAR}`,
      image: IMAGE_AWARD_GRAND_PRIX,
      events: [
        {
          isIconCheckCircle: true,
          title: '',
          date: 'Проходит прямо сейчас',
          paragraphs: [
            `Определяем номинантов на звание лучших врачей всей страны и лучших клиник каждого округа,
             Москвы, Санкт-Петербурга и Республики Крым.
             Встречаемся на Гран-при премии ПроДокторов, чтобы назвать победителей и вручить награды.`,
          ],
        },
      ],
      button: {
        text: 'Смотреть трансляцию',
        href: GRAND_PRIX_TRANSLATION_HREF,
      },
    },
  },

  [EVENT_DATES.grandResults.toISOString()]: {
    isDarkTheme: true,
    banner: {
      title: `Итоги Гран-при премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Гран-при премии ${EVENT_YEAR}`,
      image: IMAGE_AWARD_GRAND_PRIX,
      events: [
        {
          isIconCheckCircle: true,
          title: '',
          date: EVENT_DATE_TEXTS.grandTranslation,
          paragraphs: [
            'Назвали лучших врачей всей страны и лучшие клиники каждого округа, Москвы, Санкт-Петербурга и Республики Крым.',
          ],
        },
      ],
      button: {
        text: 'Смотреть результаты',
        href: `https://award.prodoctorov.ru/grand-prix/${EVENT_YEAR}/`,
      },
    },
  },
}
