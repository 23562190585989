var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.MainComponent,
    {
      tag: "component",
      attrs: { width: _setup.isMobile ? undefined : 360, scrollable: "" },
      model: {
        value: _setup.isVisible,
        callback: function ($$v) {
          _setup.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "VCard",
        {
          staticClass: "ui-kit-color-text",
          attrs: { "max-height": _setup.isMobile ? undefined : 500 },
        },
        [
          !_setup.isMobile
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center pa-4" },
                    [
                      _c(
                        "VIcon",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "uiKitIconSecondary", size: "24" },
                          on: {
                            click: function ($event) {
                              _setup.isVisible = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          ui-icon-close-not-a-circle\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                        [
                          _vm._v(
                            "\n          Дарим скидку 300 ₽ за честный отзыв\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("VDivider"),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _setup.isMobile
            ? _c("div", { staticClass: "d-flex align-center px-4 pt-6 mb-2" }, [
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                  [
                    _vm._v(
                      "\n        Дарим скидку 300 ₽ за честный отзыв\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "VCardText",
            { staticClass: "px-0", class: _setup.isMobile ? "py-0" : "py-4" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_body-1 ui-kit-color-text-secondary px-4 mb-2",
                },
                [
                  _vm._v(
                    "\n        Важно любое ваше мнение: позитивное или негативное.\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _setup.openEventPayload.doctorReviewUrl &&
              _setup.openEventPayload.lpuReviewUrl
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_subtitle-1 ui-kit-color-text px-4 mt-5",
                      },
                      [_vm._v("\n          Можно оставить отзыв\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass:
                          "d-flex align-center text-decoration-none px-4 py-3 mt-2",
                        attrs: {
                          href: _setup.openEventPayload.doctorReviewUrl,
                        },
                      },
                      [
                        _c(
                          "VIcon",
                          {
                            staticClass: "mr-8",
                            attrs: { color: "uiKitIconSecondary" },
                          },
                          [_vm._v("\n            ui-icon-doctor\n          ")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-text ui-text_body-1" }, [
                          _vm._v("\n            О враче клиники\n          "),
                        ]),
                        _vm._v(" "),
                        _c("VSpacer"),
                        _vm._v(" "),
                        _c(
                          "VIcon",
                          { attrs: { color: "uiKitIconSecondary" } },
                          [
                            _vm._v(
                              "\n            ui-icon-arrow-right\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass:
                          "d-flex align-center text-decoration-none px-4 py-3 mt-2",
                        attrs: { href: _setup.openEventPayload.lpuReviewUrl },
                      },
                      [
                        _c(
                          "VIcon",
                          {
                            staticClass: "mr-8",
                            attrs: { color: "uiKitIconSecondary" },
                          },
                          [_vm._v("\n            ui-icon-clinic\n          ")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-text ui-text_body-1" }, [
                          _vm._v("\n            О клинике\n          "),
                        ]),
                        _vm._v(" "),
                        _c("VSpacer"),
                        _vm._v(" "),
                        _c(
                          "VIcon",
                          { attrs: { color: "uiKitIconSecondary" } },
                          [
                            _vm._v(
                              "\n            ui-icon-arrow-right\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _setup.openEventPayload.lpuList
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_subtitle-1 ui-kit-color-text px-4 mt-5 mb-2",
                      },
                      [
                        _vm._v(
                          "\n          Можно оставить отзыв о приёме в клинике\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_setup.openEventPayload.lpuList, function (lpu) {
                      return _c(
                        "a",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          key: lpu.reviewUrl,
                          staticClass:
                            "d-flex align-center text-decoration-none px-4 py-2",
                          attrs: { href: lpu.reviewUrl },
                        },
                        [
                          _c("div", { staticClass: "mr-4" }, [
                            _c(
                              "div",
                              { staticClass: "ui-text ui-text_body-1" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(lpu.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ui-text ui-text_body-2 ui-kit-color-text-secondary",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(lpu.address) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("VSpacer"),
                          _vm._v(" "),
                          _c(
                            "VIcon",
                            { attrs: { color: "uiKitIconSecondary" } },
                            [
                              _vm._v(
                                "\n            ui-icon-arrow-right\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "VSheet",
            {
              class: _setup.isMobile ? "px-4 pb-4 mt-5" : "pa-2",
              attrs: {
                elevation:
                  _setup.isMobile || !_setup.openEventPayload.lpuList ? 0 : 8,
              },
            },
            [
              _c(
                "VBtn",
                {
                  staticClass: "ui-kit-color-primary",
                  attrs: { color: "uiKitBgPrimary", depressed: "", block: "" },
                  on: { click: _setup.handleClickButton },
                },
                [_vm._v("\n        Хорошо\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }