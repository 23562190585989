import { CLUSTER_POPUP_CLASSES } from 'components/common/PopupMap/constants'

export function getLpuItemTemplate({
  name,
  phone,
  address,
  id,
  lpuHref,
}) {
  const phoneData = phone ? `data-phone="${phone}"` : ''
  const lpuHrefTemplate = lpuHref ? `data-lpu-href="${lpuHref}"` : ''

  return `
        <div
            class="d-flex justify-space-between cursor-pointer ${CLUSTER_POPUP_CLASSES.lpu}"
            ${phoneData}
            data-address="${address}"
            data-id="${id}"
            data-name="${name}"
            ${lpuHrefTemplate}
            data-qa="popup_map_lpu_container"
        >
            <div
                class="py-2 ui-text ui-text_body-1 mr-auto"
                data-qa="popup_map_name_lpu_text"
            >
                ${name}
            </div>
            <span class="ui-icon-arrow-right my-auto ui-kit-color-text-info"></span>
        </div>
    `
}
