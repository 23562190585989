import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { apiScheduleTelemed, apiScheduleDoctors } from 'www/api'
import { PlaceType } from 'components/common/DoctorPriceBlock/types'
import { getPriceList, sortLpuAddressList } from 'components/common/DoctorPageLpuList/functions'
import { useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
import type { LpuAddressList } from 'components/common/DoctorPageLpuList/types'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import { requestSlots } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/api'
import { getTimezoneOffsetInHours } from 'utils'
import { PLACE_TYPES } from 'components/common/DoctorPriceBlock/constants'
import { ApiScheduleDoctorsResponsePost } from 'www/api/apiScheduleDoctors.types'

const locationHash = window.location.hash
let placeTypeFromHash: PlaceType = 'clinic'

if (locationHash.includes('#filter=')) {
  const hashFilterValue = locationHash.match(/#filter=(\w+)/)?.[1] || ''

  if ((PLACE_TYPES as ReadonlyArray<string>).includes(hashFilterValue)) {
    placeTypeFromHash = hashFilterValue as PlaceType
  }
}

export default defineStore('doctorPageLpuListStore', () => {
  const externalDataStore = useExternalDataStore()
  const { lpuAddressList, currentDate, townTimedelta } = storeToRefs(externalDataStore)

  const filterType = ref<PlaceType>(placeTypeFromHash)
  const expandedPanels = ref<number[]>([0])

  function checkAppointmentPriceByFilter(workplace: LpuAddressList[number]['workplaces'][number]) {
    const priceList = getPriceList(workplace)

    switch (filterType.value) {
      case 'clinic':
        return typeof priceList.price === 'number'
      case 'home':
        return typeof priceList.priceHome === 'number'
      case 'telemed':
        return typeof priceList.priceOnline === 'number' || (
          window.FEATURE_FLAGS.pd_medtochka_telemed && typeof priceList.priceMedtochkaTelemed === 'number'
        )
    }
  }

  const lpuAddressListFiltered = computed(
    () => sortLpuAddressList(lpuAddressList.value?.filter(
      lpuAddress => filterType.value === 'clinic'
      || lpuAddress.workplaces.some(workplace => checkAppointmentPriceByFilter(workplace)),
    ), filterType.value) || [],
  )

  async function updateSlots() {
    if (!lpuAddressList.value || filterType.value === 'home') {
      return
    }

    lpuAddressList.value.forEach(lpuAddress => {
      useDoctorAppointmentCardStore(lpuAddress.lpu.id)().isRequestSlotsDone = false
    })

    try {
      if (window.FEATURE_FLAGS.pd_medtochka_telemed) {
        if (filterType.value === 'telemed') {
          const response = await apiScheduleTelemed.post({
            days: 14,
            user_start_date: currentDate.value,
            only_free: true,
            user_timezone: getTimezoneOffsetInHours(),
            lpu_params: lpuAddressList.value
              .filter(
                lpuAddress => (
                  lpuAddress.appointment_telemed
                  && lpuAddress.appointment_direct
                  && lpuAddress.has_slots_telemed_in_clinic
                ) || (
                  lpuAddress.appointment_telemed
                  && lpuAddress.enable_appointment_telemed_in_medtochka
                  && lpuAddress.has_slots_telemed_in_medtochka
                ),
              )
              .map(lpuAddress => ({
                lpu_id: lpuAddress.lpu_id,
                schedule_params: [{
                  doctor_id: lpuAddress.doctor_id ?? 0,
                  type_schedule: useDoctorAppointmentCardStore(lpuAddress.lpu_id)().scheduleType,
                }],
              })),
          })

          response.data.forEach(responseItem => {
            useDoctorAppointmentCardStore(responseItem.lpu_id)().calendarSlots = apiScheduleTelemed.transformSlots(
              responseItem.schedule_data[0]?.schedule,
            )
          })
        } else if (filterType.value === 'clinic') {
          const response = await apiScheduleDoctors.post({
            days: 14,
            dt_start: currentDate.value,
            all_slots: false,
            town_timedelta: townTimedelta.value ?? 0,
            doctors_lpus: lpuAddressList.value
              .filter(lpuAddress => lpuAddress.has_slots_in_clinic)
              .map(lpuAddress => ({
                doctor_id: lpuAddress.doctor_id ?? 0,
                lpu_id: lpuAddress.lpu_id,
                lpu_timedelta: lpuAddress.lpu.town.timedelta ?? 0,
                has_slots: lpuAddress.has_slots,
              })),
          })

          response.data.result.forEach(responseItem => {
            useDoctorAppointmentCardStore(responseItem.lpu_id)().calendarSlots = responseItem.slots
          })
        }
      } else {
        const slotsResponse: ApiScheduleDoctorsResponsePost['result'] = await requestSlots({
          slotsData: {
            days: 14,
            allSlots: false,
            nowDateString: currentDate.value,
            timedelta: townTimedelta.value ?? 0,
            doctorsAndLpus: lpuAddressList.value.map(lpuAddress => ({
              doctorId: lpuAddress.doctor_id ?? 0,
              lpuId: lpuAddress.lpu_id,
              timedelta: lpuAddress.lpu.town.timedelta ?? 0,
              hasSlots: lpuAddress.has_slots,
            })),
          },
          isWorkplace: true,
          isTelemed: filterType.value === 'telemed',
          isAllLpus: true,
        })

        slotsResponse.forEach(slotsResponseItem => {
          useDoctorAppointmentCardStore(slotsResponseItem.lpu_id)().calendarSlots = slotsResponseItem.slots
        })
      }
    } catch { /* empty */ } finally {
      lpuAddressList.value?.forEach(lpuAddress => {
        useDoctorAppointmentCardStore(lpuAddress.lpu.id)().isRequestSlotsDone = true
      })
    }
  }

  return {
    filterType,
    expandedPanels,
    lpuAddressListFiltered,
    updateSlots,
  }
})
