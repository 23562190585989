export default {
  data: () => ({
    expansionPanel: [
      {
        title: 'К каким врачам можно записаться по клубной цене?',
        description:
                      `Только к тем, у кого возле цены приёма стоит метка клуба.
                      Если метки нет, значит клиника не вступила в клуб.`,
      }, {
        title: 'На что действует скидка?',
        description:
                      `Клубная скидка даётся только на консультацию врача.
                      В некоторых случаях врач оказывает допуслуги: мазок, биопсия, инъекция и т.д.
                      Эти услуги оплачиваются отдельно.`,
      }, {
        title: 'Можно ли вернуть невозвратную сумму?',
        description:
                      `Вы можете связаться с клиникой
                      и договориться перенести приём.
                      Вернуть деньги нельзя.`,
      }, {
        title: 'Как подтвердить в клинике, что приём уже наполовину оплачен?',
        description:
                      `Обычно в клинике уже об этом знают.
                      Но чтобы избежать заминок, покажите купон.
                      Он придёт на email и будет доступен в личном кабинете на сайте.`,
      }, {
        title: 'Действуют ли клубные цены без оплаты на сайте?',
        description:
                      `Нет. Если не оплатить 50% стоимости
                      приёма в течение 20 минут при записи
                      к врачу на сайте, в клинике надо будет
                      оплатить полную стоимость приёма без скидки.`,
      },
    ],
  }),
}
