import Vue from 'components'
import { pinia, vuetify } from 'www/plugins'
// eslint-disable-next-line import/extensions
import DoctorPageLpuList from 'components/common/DoctorPageLpuList/DoctorPageLpuList.vue'

const el = document.querySelector('.vue-doctor-page-lpu-list-mount')

if (el) {
  new Vue({
    el,
    name: 'DoctorPageLpuListRoot',
    vuetify,
    pinia,
    components: {
      DoctorPageLpuList,
    },
  })
}
