import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import PsychDoctorInformation from 'components/common/PsychDoctorInformation/PsychDoctorInformation'

[...document.querySelectorAll('.vue-psych-doctor-information-mount')].forEach(el => {
  const VueInstance = new Vue({
    vuetify,
    components: { PsychDoctorInformation },
  })

  VueInstance.$mount(el)
})
