<template>
  <div
    v-if="isMobile"
    data-qa="doctor_service_prices_appointment_switch"
    class="filter-online px-3 d-flex justify-content-between align-center"
    @click="handleClick"
  >
    <div class="d-flex align-center">
      <VIcon
        class="mr-2"
        color="primary"
      >
        ui-icon-calendar-date-and-clock
      </VIcon>
      <div
        class="ui-text ui-text_body-2 ui-kit-color-text"
        v-text="'Только услуги с онлайн-записью'"
      />
    </div>
    <VSwitch
      v-model="isEnabledOnline"
      inset
      class="mr-n3"
      @click.stop
      @change="handleChange"
    />
  </div>
  <VSwitch
    v-else
    v-model="isEnabledOnline"
    class="mt-0"
    inset
    @change="handleChange"
  >
    <template #label>
      <div
        class="ui-text ui-text_body-2 ui-kit-color-text"
        v-text="'Только услуги с онлайн-записью'"
      />
    </template>
  </VSwitch>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{
  isEnabledOnlineApp: boolean
}>()
const isEnabledOnline = ref(props.isEnabledOnlineApp)
const isMobile = window.MOBILE_VERSION

const emit = defineEmits(['online-switch:change'])

function handleClick() {
  isEnabledOnline.value = !isEnabledOnline.value
  emit('online-switch:change', { isEnabledOnline: isEnabledOnline.value })
}

function handleChange(val: boolean) {
  emit('online-switch:change', { isEnabledOnline: val })
}
</script>

<style scoped lang="scss">
@import '~www/themes/doctors/common/variables';

.filter-online {
  width: 100%;
  height: 48px;
  border-radius: $border-radius-md;
  background-color: $ui-kit-bg-secondary;
}
</style>
