import UserStorage from 'modules/UserStorage'

const getServiceData = () => ({
  lpuId: null,
  dtype: null,
  price: null,
  dclass: null,
  misServiceId: null,
  priceWithDiscount: null,
  typeOrSynonymName: null,
  categoryOrClassName: null,
  classTranslit: null,
  isShowPhoneOnly: false,
  hasIntervals: false,
})

const getLpuData = () => ({
  id: null,
  name: null,
  phone: null,
  address: null,
  imageSrc: null,
  isPatronymicNeeded: null,
  isEnabledClubDiscount: false,
  /**
     * interface Schedule {
     *     [day: String]: {
     *         timeStart: String,
     *         timeEnd: String,
     *     }
     *  }
     */
  schedule: {},
  townName: '',
  townTimeDelta: null,
})

/**
 * ISelectedDoctor {
 *     id: number | null,
 *     fullName: string,
 *     imageSrc: string,
 *     experience: string,
 *     category: string,
 *     science: string,
 *     narrowSpeciality: string,
 *     relatedPairId: number | null,
 *     nearestAppointmentDate: Date | null,
 *     nearestAppointmentDateString: string,
 *     formattedNearestAppointmentDate: string,
 * }
 * */
const getSelectedDoctor = () => ({
  id: null,
  fullName: '',
  imageSrc: '',
  experience: '',
  category: '',
  science: '',
  workplace: {},
  narrowSpeciality: '',
  relatedPairId: null,
  nearestAppointmentDate: null,
  nearestAppointmentDateString: '',
  formattedNearestAppointmentDate: '',
})

/**
 * @typedef Age
 * @property { number | null } min
 * @property { number | null } max
 * */
/**
 * @typedef Workplace
 * @property { Age } age
 * */
/**
 * @typedef DoctorData
 * @property { number | null } id
 * @property { string } fullName
 * @property { string } imageSrc
 * @property { string } experience
 * @property { string } category
 * @property { string } science
 * @property { string } narrowSpeciality
 * @property { Workplace } workplace
 * @property { string[] } [specialitiesNames]
 * */

/** @return { DoctorData[] } */
const getDoctorsData = () => []

/**
 * ISelectedEquipment {
 *     id: number | null,
 *     name: string,
 *     imageSrc: string,
 *     power: string,
 *     numberOfSlices: string,
 *     isOpen: boolean | null,
 *     isIncreasedWeight: boolean | null,
 *     isDigital: boolean | null,
 *     isVideoEndoscopy: boolean | null,
 *     relatedPairId: number | null,
 *     nearestAppointmentDate: Date | null,
 *     nearestAppointmentDateString: string,
 *     formattedNearestAppointmentDate: string,
 * }
 * */
const getSelectedEquipment = () => ({
  id: null,
  name: '',
  imageSrc: '',
  power: '',
  numberOfSlices: '',
  isOpen: null,
  isIncreasedWeight: null,
  isDigital: null,
  isVideoEndoscopy: null,
  relatedPairId: null,
  nearestAppointmentDate: null,
  nearestAppointmentDateString: '',
  formattedNearestAppointmentDate: '',
})

/**
 * TEquipmentsData = Array<ISelectedEquipment>
 * */
const getEquipmentsData = () => []

/**
 * ISelectedDoctorEquipmentRelation {
 *     doctorId: number | null,
 *     equipmentId: number | null,
 *     nearestAppointmentDate: Date | null,
 *     nearestAppointmentDateString: string,
 *     formattedNearestAppointmentDate: string,
 * }
 *
 * TDoctorEquipmentRelations<ISelectedDoctorEquipmentRelation>
 * */
const getDoctorEquipmentRelationsData = () => []

const getAppointmentRegistrationTypes = () => ({
  isDirect: null,
  isExpress: null,
  isAvailableExpress: null,
})

const getAppointmentObjectTypes = () => ({
  isServices: null,
  isDoctor: null,
})

const getAppointmentCall = () => ({
  isDialogRender: false,
  attempts: null,
  id: null,
  status: null,
  phone: null,
  date: null,
})

const getAppointmentCallTimes = () => ({
  minHoursToCall: null,
  date: null,
  /**
     * interface Times = Array<Time>
     *
     * type Time = {
     *     text: String,
     *     value: String,
     *     date: String,
     * }
     * */
  times: [],
})

const getInitCalendarCommonData = () => ({
  days: 14,
  day: null,
  time: null,
  dateNow: null,
  dateName: null,
  inputDay: null,
  isSlotFixed: null,
})

const getInitCalendarServicesData = () => ({
  lpuId: null,
  deviceId: null,
  synonymId: null,
  timedelta: null,
})

const getInitCalendarDoctorData = () => ({
  /**
     * interface DoctorAndLpu {
     *     doctorId: number
     *     lpuId: number
     * }
     * type DoctorsAndLpus = Array<DoctorAndLpu>
     */
  doctorsAndLpus: [],
  /**
     * type LpuId = number
     * type Timedelta = number
     * type LpuTimedeltas = Array<[LpuId, Timedelta]>
     */
  lpuTimedeltas: [],
  customRequestLimit: 0,
})

const getInitAdditionalData = () => ({
  specialityId: null,
  placeType: '',
  serverTimedelta: '',
  townVrachUrl: '',
})

const getInitUserData = () => ({
  name: '',
  phone: '',
  surname: '',
  birthDay: '',
  patronymic: '',
})

const getPatientData = () => ({
  id: null,
  uuid: '',
  name: '',
  surname: '',
  patronymic: '',
  hasPatronymic: true,
  birthDay: '',
  shortFio: '',
  age: '',
  isMain: false,
  isOther: false,
})

const getSelectedDate = () => ({
  dateName: '',
  day: '',
  isSlotFixed: false,
  time: '',
})

const getEditableCalendar = () => ({
  isChanging: false,
  calendarList: {},
  selectedDate: getSelectedDate(),
})

const getUrlsData = () => ({
  iconCancel: '#',
  iconSuccess: '#',
  clubPdfs: {},
  townLpu: null,
  townDoctor: null,
  townServices: null,
  referrerToLpuServiceList: '',
  referrerToLpuList: null,
  referrerToDoctorList: null,
})

const getButtonState = () => ({
  get: '',
  post: '',
})

const errorData = () => ({
  code: '',
  isDialogRender: false,
  isEnabled: false,
  isPersistent: false,
  title: 'Не получилось записаться',
  titleMaxWidth: null,
  subtitle: '',
  showPhone: false,
  buttonState: getButtonState(),
  redirectUrl: '',
  buttonFirstCallback: () => {},
  buttonSecondCallback: () => {},
})

const successData = () => ({
  code: '',
  isDialogRender: false,
  isEnabled: false,
  title: 'Вы записаны',
  subtitle: '',
  showPhone: false,
  buttonState: getButtonState(),
  medtochkaUrl: '',
  isEnableMedtochkaRedirect: true,
  doctorNameFromMIS: '',
  equipmentNameFromMIS: '',
})

const getLocalStorageData = () => (new UserStorage({
  key: 'AppointmentData',
  data: null,
}))

/** Данные из контекста для вывода компонента успешной/неудачной записи */
const getInitAppointmentStatusData = () => ({
  isError: false,
  isSuccess: false,
  title: '',
  subtitle: '',
  medtochkaUrl: '',
  buttonState: getButtonState(),
  isEnableMedtochkaRedirect: false,
})

const getOfferData = () => ({
  lpuPrice: null,
  couponPrice: null,
  cardDateEnd: null,
  cardPrice: null,
  totalPrice: null,
  cardLifetimeMonths: null,
  clubDiscount: null,
})

const getCouponData = () => ({
  id: null,
  dateCreated: null,
  lifeTime: null,
  clubCouponPrice: null,
  price: null,
  fullPrice: null,
  clubLpuPrice: null,
  paymentStatus: null,
  cardPrice: null,
  totalPrice: null,
  clubCardLifetimeMonths: null,
})

const getInitClubStatusData = () => ({
  couponData: getCouponData(),
  dataErrorFromPage: {
    has: null,
    key: null,
  },
  medtochkaDetailUrl: null,
  couponId: null,
  clubLpuPrice: null,
  isClubRegistration: null,
})

export * from './interfacePleminaryConsultation'
export * from './interfacesFamilyProfiles'
export * from './typedInterfaces'

export {
  getServiceData,
  getLpuData,
  getSelectedDoctor,
  getDoctorsData,
  getSelectedEquipment,
  getEquipmentsData,
  getDoctorEquipmentRelationsData,
  getAppointmentRegistrationTypes,
  getAppointmentObjectTypes,
  getAppointmentCall,
  getAppointmentCallTimes,
  getInitCalendarCommonData,
  getInitCalendarServicesData,
  getInitCalendarDoctorData,
  getInitAdditionalData,
  getInitUserData,
  getPatientData,
  getEditableCalendar,
  getSelectedDate,
  getUrlsData,
  errorData,
  successData,
  getLocalStorageData,
  getInitAppointmentStatusData,
  getOfferData,
  getCouponData,
  getInitClubStatusData,
}
