import { testRequiredOptions } from 'utils'
import { apiScheduleTelemed } from 'www/api'
import { AppointmentModules } from 'modules/appointment-modules'
import { PAGE_TYPE_DATA } from 'modules/Schedule/constants'

const {
  getAppointmentLpusSlots,
  getAppointmentDoctorsSlots,
  getAppointmentServicesSlots,
} = new AppointmentModules()

/**
 * @description
 *
 * Производит запрос за слотами
 *
 * @param { Object } options
 * @param { String } options.pageType - тип страницы, одно из значений константы 'PAGE_TYPE_DATA'
 * @param { Object } options.data - данные для запроса
 *
 * @return { Promise | undefined }
 * */

function scheduleRequest({ pageType, data, isTelemed } = {}) {
  const testOptions = testRequiredOptions({
    module: 'scheduleRequest',
    requiredOptions: { data, pageType },
  })

  if (!testOptions) {
    return
  }

  if (isTelemed) {
    return apiScheduleTelemed.post(data)
  }

  switch (pageType) {
    case PAGE_TYPE_DATA.lpu:
      return getAppointmentLpusSlots(data)
    case PAGE_TYPE_DATA.doctors:
    case PAGE_TYPE_DATA.doctorsLpu:
      return getAppointmentDoctorsSlots(data)
    case PAGE_TYPE_DATA.services:
      return getAppointmentServicesSlots(data)
  }
}

export default scheduleRequest
