import type { LpuAddressList } from 'components/common/DoctorPageLpuList/types'
import type { PlaceType } from 'components/common/DoctorPriceBlock/types'

const filterTypeByPrice = {
  clinic: ['price'],
  home: ['price_go'],
  telemed: ['pd_price_online', 'mt_price_online'],
} as const

let filterType: PlaceType = 'clinic'

const [, chosenSpec] = window.location.hash?.match(/#speciality=([\w-]+)/) || []

const isChosenFilterHasPrice = (workplace: LpuAddressList[number]['workplaces'][number]) => (
  filterTypeByPrice[filterType].some(price => workplace[price])
)

const compareFunction = (a: LpuAddressList[number], b: LpuAddressList[number]) => {
  const aHasSelectedSpec = a.workplaces.some(workplace => workplace.spec.translit === chosenSpec
      && isChosenFilterHasPrice(workplace))
  const bHasSelectedSpec = b.workplaces.some(workplace => workplace.spec.translit === chosenSpec
      && isChosenFilterHasPrice(workplace))

  if (aHasSelectedSpec && !bHasSelectedSpec) {
    return -1
  }

  if (bHasSelectedSpec && !aHasSelectedSpec) {
    return 1
  }

  return 0
}

export default function sortLpuAddressList(lpuAddressList: LpuAddressList | undefined, placeType: PlaceType) {
  if (!lpuAddressList || !chosenSpec) {
    return lpuAddressList
  }

  filterType = placeType
  return lpuAddressList.sort(compareFunction)
}
