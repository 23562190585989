var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEmptySlotList
    ? _c("CalendarStub")
    : _c(
        "div",
        {
          staticClass: "b-appointment-calendar",
          class: {
            "b-appointment-calendar_expanded": !!_vm.expanded.state,
          },
          attrs: { "data-lpu": _vm.lpuId, "data-doctor": _vm.doctorId },
        },
        [
          _c(
            "div",
            {
              ref: "days-container",
              staticClass: "b-appointment-calendar__days",
            },
            [
              _c(
                "div",
                { staticClass: "b-appointment-calendar__days-container" },
                [
                  _c(
                    "div",
                    {
                      ref: "days-wrapper",
                      staticClass: "b-appointment-calendar__days-wrapper",
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "active-day",
                          staticClass: "b-appointment-calendar__active-day",
                          class: {
                            "b-appointment-calendar__active-day_start_coord":
                              !_vm.daysLeftCoordHash[_vm.curSlots],
                          },
                          style: {
                            left: `${_vm.daysLeftCoordHash[_vm.curSlots]}px`,
                          },
                          attrs: { "data-active-day": _vm.curSlots },
                        },
                        [
                          _c("div", {
                            staticClass:
                              "b-appointment-calendar__active-day-inner",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.days, function (day, index) {
                        return _c(
                          "div",
                          {
                            key: `day-${index}`,
                            ref: "day",
                            refInFor: true,
                            staticClass: "b-appointment-calendar__day",
                            class: {
                              "b-appointment-calendar__day_is_active":
                                _vm.curSlots === day,
                              "b-appointment-calendar__day_is_disabled":
                                !_vm.data[day].length,
                            },
                            attrs: {
                              "data-day": day,
                              "data-week": Math.floor(index / 7) + 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onClickDay($event, day)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "b-appointment-calendar__day-inner",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "b-appointment-calendar__day-name",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getWeekDay(day)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "b-appointment-calendar__day-num",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getWeekDay(day, "num")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "b-appointment-calendar__next-days-btn",
                  class: {
                    "b-appointment-calendar__next-days-btn_last-week":
                      _vm.curWeek === _vm.weeks,
                  },
                  attrs: {
                    "data-qa":
                      _vm.QALocators[
                        _vm.curWeek === _vm.weeks
                          ? "button-prev"
                          : "button-next"
                      ],
                  },
                  on: { click: _vm.onClickGetNextDays },
                },
                [
                  _c("span", {
                    staticClass:
                      "b-appointment-calendar__next-icon ui-icon-arrow-right ui-icon_fz_smaller",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "b-appointment-calendar__slots-container",
              class: {
                "b-appointment-calendar__slots-container_expanded":
                  !!_vm.expanded.state,
                "b-appointment-calendar__slots-container_start-coord": !(
                  _vm.daysLeftCoordHash[_vm.curSlots] %
                  (_vm.DAY_WIDTH * 7)
                ),
              },
            },
            [
              _c("AppCalendarSlotSet", {
                attrs: {
                  "doctor-id": _vm.doctorId,
                  "base-slot-set": _vm.data,
                  "selected-slot": _vm.dataFromSelectedDate,
                  "selected-date": _vm.curSlots,
                  "expanded-state": _vm.expanded.state,
                  "dynamic-slot-set": _vm.slotsDataObj,
                },
                on: _vm._d({}, [
                  _vm.eventClickSlot,
                  _vm.handleClickOnTime,
                  _vm.eventClickExpand,
                  _vm.onClickExpandBtn,
                  _vm.eventClickCollapse,
                  _vm.onClickCollapseBtn,
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("PreliminaryConsultationModal", {
            attrs: { "is-visible": _vm.isVisibleInvasiveModal },
            on: {
              "preliminary-consultation-modal:click-close":
                _vm.handleInvasiveModalClose,
              "preliminary-consultation-modal:click-continue":
                _vm.handleInvasiveModalContinue,
              "preliminary-consultation-modal:visible":
                _vm.handleInvasiveModalVisible,
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }