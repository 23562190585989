import JSON5 from 'process-js-objects'
import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import { dispatchCustomEventGlobally } from 'utils'
// eslint-disable-next-line import/extensions
import ReviewPowerInfo from 'components/common/ReviewPowerInfo/ReviewPowerInfo.vue'

const el = document.querySelector('.vue-review-power-info-mount')

if (el) {
  new Vue({
    vuetify,
    el,
    render: h => h(ReviewPowerInfo),
  })
}

function dispatchReviewPowerInfoOpen(event: MouseEvent) {
  if (!(event.target instanceof HTMLElement)) {
    return
  }

  const element = event.target.closest('[data-review-power-info-open]')

  if (!element) {
    return
  }

  event.preventDefault()

  const data = JSON5.parse(element.getAttribute('data-review-power-info-open') ?? '{}')

  dispatchCustomEventGlobally('review-power-info:open', data)
}

window.addEventListener('click', dispatchReviewPowerInfoOpen)
