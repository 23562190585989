var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isMobile
    ? _c(
        "div",
        {
          staticClass:
            "filter-online px-3 d-flex justify-content-between align-center",
          attrs: { "data-qa": "doctor_service_prices_appointment_switch" },
          on: { click: _setup.handleClick },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "VIcon",
                { staticClass: "mr-2", attrs: { color: "primary" } },
                [_vm._v("\n      ui-icon-calendar-date-and-clock\n    ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "ui-text ui-text_body-2 ui-kit-color-text",
                domProps: {
                  textContent: _vm._s("Только услуги с онлайн-записью"),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("VSwitch", {
            staticClass: "mr-n3",
            attrs: { inset: "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
              change: _setup.handleChange,
            },
            model: {
              value: _setup.isEnabledOnline,
              callback: function ($$v) {
                _setup.isEnabledOnline = $$v
              },
              expression: "isEnabledOnline",
            },
          }),
        ],
        1
      )
    : _c("VSwitch", {
        staticClass: "mt-0",
        attrs: { inset: "" },
        on: { change: _setup.handleChange },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("div", {
                  staticClass: "ui-text ui-text_body-2 ui-kit-color-text",
                  domProps: {
                    textContent: _vm._s("Только услуги с онлайн-записью"),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _setup.isEnabledOnline,
          callback: function ($$v) {
            _setup.isEnabledOnline = $$v
          },
          expression: "isEnabledOnline",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }