<template>
  <VApp data-psych-doctor-information>
    <VIcon
      @click="clickHandle"
    >
      ui-icon-circle-info
    </VIcon>
    <VBottomSheet
      v-if="isMobile"
      v-model="isActive"
    >
      <VSheet>
        <div class="bottom-sheet-container px-4 py-6">
          <div class="ui-text ui-text_h6 ui-kit-color-text">
            {{ props.title }}
          </div>
          <div
            v-if="props.subtitle"
            class="ui-text ui-text_body-1 ui-kit-color-text-secondary"
          >
            {{ props.subtitle }}
          </div>
          <div class="my-4 ui-text ui-text_body-1 ui-kit-color-text">
            {{ props.description }}
          </div>
          <VBtn
            block
            color="ui-kit-color-bg-primary"
            elevation="0"
            @click="clickHandle"
          >
            <span class="ui-kit-color-primary">
              Понятно
            </span>
          </VBtn>
        </div>
      </VSheet>
    </VBottomSheet>
    <VDialog
      v-else
      v-model="isActive"
      width="456"
    >
      <VSheet>
        <VSheet
          class="pa-2 d-flex align-center"
          min-height="48"
        >
          <VSheet
            width="24"
            height="24"
          >
            <VIcon @click="clickHandle">
              ui-icon-close-not-a-circle
            </VIcon>
          </VSheet>
          <div class="ml-8 ui-text">
            <div class="ui-text_h6 ui-kit-color-text">
              {{ props.title }}
            </div>
            <div
              v-if="props.subtitle"
              class="ui-text_body-2 ui-kit-color-text-secondary"
            >
              {{ props.subtitle }}
            </div>
          </div>
        </VSheet>
        <VDivider />
        <div class="pa-6">
          <div class="ui-text ui-text_body-1 ui-kit-color-text-secondary">
            {{ props.description }}
          </div>
          <VCardActions
            class="pa-0 mt-6"
          >
            <VSpacer />
            <VBtn
              color="primary"
              elevation="0"
              @click="clickHandle"
            >
              Понятно
            </VBtn>
          </VCardActions>
        </div>
      </VSheet>
    </VDialog>
  </VApp>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { VBottomSheet, VDialog } from 'vuetify/lib'

const props = defineProps<{
  title: string
  subtitle?: string
  description: string
}>()

const isActive = ref(false)
const isMobile = window.MOBILE_VERSION

function clickHandle() {
  isActive.value = !isActive.value
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-psych-doctor-information] {
  @include v-app-reset;
}
</style>

<style lang="scss" scoped>
.bottom-sheet-container {
  overflow-y: auto;
  max-height: 90vh;
}
</style>
