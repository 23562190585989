import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { createDevNotice, getTimezoneOffsetInHours, isNetworkDisconnect } from 'utils'
import { apiScheduleDoctors, apiScheduleServices, apiScheduleTelemed } from 'www/api'
import type { Slots } from 'www/api/types'
import { type RedirectValue } from 'www/constants'
import { useDoctorPageLpuListStore, useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
import { requestSlots } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/api'
import { ScheduleType } from 'www/api/apiScheduleTelemed.types'
import { LpuAddressList, SelectWorkplaceOrServiceItem, SynonymPriceList } from 'components/common/DoctorPageLpuList/types'
import { getPriceList } from 'components/common/DoctorPageLpuList/functions'

export default (lpuId: number) => defineStore(`doctorAppointmentCard/${lpuId}`, () => {
  const doctorPageLpuListStore = useDoctorPageLpuListStore()
  const { filterType } = storeToRefs(doctorPageLpuListStore)
  const externalDataStore = useExternalDataStore()
  const { currentDate, townTimedelta } = storeToRefs(externalDataStore)

  const lpuId = ref(0)
  const doctorId = ref(0)
  const timedelta = ref(getTimezoneOffsetInHours())
  const hasSlots = ref(false)
  const workplaces = ref<LpuAddressList[number]['workplaces']>([])
  const services = ref<SynonymPriceList>([])
  const nowDateString = ref(format(new Date(), 'yyyy-MM-dd', { locale: ru }))
  const selectedItem = ref<SelectWorkplaceOrServiceItem>({
    price: null,
    agesText: '',
    isWorkplace: false,
  })
  const calendarSlots = ref<Slots>({})
  const fullCalendarSlots = ref({})
  const isRequestSlotsDone = ref(false)
  const isVisibleFullCalendar = ref(false)
  const isRequestedFullCalendar = ref(false)
  const redirectValue = ref<RedirectValue>()

  const selectedItemPriceList = computed(() => getPriceList(selectedItem.value.workplace))

  const scheduleType = computed<ScheduleType>(() => {
    const { priceOnline, priceMedtochkaTelemed } = selectedItemPriceList.value
    let type: 'lpu' | 'mt'

    if (redirectValue.value) {
      type = redirectValue.value === 'telemed' ? 'lpu' : 'mt'
    } else {
      type = (
        typeof priceOnline === 'number'
        && typeof priceMedtochkaTelemed === 'number'
        && priceMedtochkaTelemed < priceOnline
      ) || typeof priceOnline !== 'number'
        ? 'mt'
        : 'lpu'
    }

    return apiScheduleTelemed.getScheduleType({
      telemed: priceOnline,
      telemedMedtochka: priceMedtochkaTelemed,
    }, type)
  })

  const workplacesWithSelectedFilterType = computed(() => workplaces.value.filter(workplace => {
    const priceList = getPriceList(workplace)

    switch (filterType.value) {
      case 'clinic':
        return typeof priceList.price === 'number'
      case 'home':
        return typeof priceList.priceHome === 'number'
      case 'telemed':
        return typeof priceList.priceOnline === 'number' || typeof priceList.priceMedtochkaTelemed === 'number'
    }

    return false
  }))

  function resetSlots() {
    isRequestedFullCalendar.value = false
    calendarSlots.value = {}
    fullCalendarSlots.value = {}
  }

  async function requestCalendarSlots({
    days = 14,
    allSlots = false,
    isFullCalendar = false,
  }: {
    days?: 14 | 30
    allSlots?: boolean
    isFullCalendar?: boolean
  } = {}) {
    if (window.FEATURE_FLAGS.pd_medtochka_telemed) {
      try {
        let slots: Slots | null = null

        isRequestSlotsDone.value = false

        if (filterType.value === 'telemed') {
          const response = await apiScheduleTelemed.post({
            days,
            user_start_date: currentDate.value,
            only_free: !isFullCalendar,
            user_timezone: getTimezoneOffsetInHours(),
            lpu_params: [{
              lpu_id: lpuId.value,
              schedule_params: [{
                doctor_id: doctorId.value ?? 0,
                type_schedule: scheduleType.value,
              }],
            }],
          })

          slots = apiScheduleTelemed.transformSlots(response.data[0].schedule_data[0]?.schedule)
        } else if (filterType.value === 'clinic') {
          if (selectedItem.value.isWorkplace) {
            const response = await apiScheduleDoctors.post({
              days,
              dt_start: currentDate.value,
              all_slots: isFullCalendar,
              town_timedelta: townTimedelta.value ?? 0,
              doctors_lpus: [{
                lpu_id: lpuId.value,
                doctor_id: doctorId.value,
                lpu_timedelta: timedelta.value,
                has_slots: hasSlots.value,
              }],
            })

            slots = response.data.result[0]?.slots
          } else {
            const response = await apiScheduleServices.post({
              days,
              day_start: currentDate.value,
              only_free: !isFullCalendar,
              town_timedelta: townTimedelta.value ?? 0,
              services: [{
                lpu_id: lpuId.value,
                service_id: selectedItem.value.service?.synonym_id ?? 0,
                doctor_id: doctorId.value ?? 0,
              }],
            })

            slots = response.data.result[0]?.slots
          }
        }

        if (slots) {
          if (isFullCalendar) {
            fullCalendarSlots.value = slots
          } else {
            calendarSlots.value = slots
          }
        }
      } catch (error) {
      // @ts-ignore
        const { message, response } = {}
        const { status } = response || {}

        if (isNetworkDisconnect({
          status,
          message,
        })) {
          return
        }

        createDevNotice({
          description: message || error,
          method: 'requestCalendarSlots',
          module: 'useDoctorAppointmentCardStore',
        })
      } finally {
        isRequestSlotsDone.value = true
      }
    } else {
      const slotsData = {
        days,
        allSlots,
        nowDateString: nowDateString.value,
        timedelta: timedelta.value,
      }

      if (selectedItem.value.isWorkplace) {
        Object.assign(slotsData, {
          doctorsAndLpus: [{
            lpuId: lpuId.value,
            doctorId: doctorId.value,
            timedelta: timedelta.value,
            hasSlots: hasSlots.value,
          }],
        })
      } else {
        Object.assign(slotsData, {
          services: [{
            lpuId: lpuId.value,
            serviceId: selectedItem.value.service?.synonym_id,
            doctorId: doctorId.value,
          }],
        })
      }

      isRequestSlotsDone.value = false

      try {
        const slots = await requestSlots({
          slotsData,
          isWorkplace: selectedItem.value.isWorkplace ?? false,
          isTelemed: filterType.value === 'telemed',
        })

        if (isFullCalendar) {
          fullCalendarSlots.value = slots
        } else {
          calendarSlots.value = slots
        }
      } catch (error) {
      // @ts-ignore
        const { message, response } = {}
        const { status } = response || {}

        if (isNetworkDisconnect({
          status,
          message,
        })) {
          return
        }

        createDevNotice({
          description: message || error,
          method: 'requestCalendarSlots',
          module: 'useDoctorAppointmentCardStore',
        })
      } finally {
        isRequestSlotsDone.value = true
      }
    }
  }

  return {
    lpuId,
    doctorId,
    timedelta,
    hasSlots,
    workplaces,
    workplacesWithSelectedFilterType,
    services,
    nowDateString,
    selectedItem,
    selectedItemPriceList,
    scheduleType,
    calendarSlots,
    fullCalendarSlots,
    isRequestSlotsDone,
    isVisibleFullCalendar,
    isRequestedFullCalendar,
    redirectValue,
    resetSlots,
    requestCalendarSlots,
  }
})
