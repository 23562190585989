import { axiosClient } from 'utils'
import type {
  ApiScheduleTelemedParamsPost,
  ApiScheduleTelemedResponsePost,
  ScheduleType,
} from 'www/api/apiScheduleTelemed.types'
import type { Slots } from 'www/api/types'

export default {
  post(params: ApiScheduleTelemedParamsPost) {
    return axiosClient.post<ApiScheduleTelemedResponsePost>('/ajax/schedule/slots_bulk/telemed/', params)
  },
  transformSlots(telemedSchedule?: ApiScheduleTelemedResponsePost[number]['schedule_data'][number]['schedule']) {
    const slots: Slots = {}

    if (!telemedSchedule) {
      return slots
    }

    telemedSchedule.reduce((_, scheduleItem) => {
      slots[scheduleItem.date] = scheduleItem.slots.map(slot => ({
        time: slot.time_start,
        free: slot.free,
        duration: slot.duration * 60,
      }))

      return slots
    }, slots)

    return slots
  },
  getScheduleType(
    prices: { telemed?: number | null, telemedMedtochka?: number | null },
    defaultType: ScheduleType,
    { isSpecialitySelected = true }: { isSpecialitySelected?: boolean } = {},
  ) {
    let scheduleType: ScheduleType = defaultType

    if (isSpecialitySelected) {
      if (
        typeof prices.telemed === 'number'
        && typeof prices.telemedMedtochka === 'number'
        && prices.telemed === prices.telemedMedtochka
      ) {
        scheduleType = 'all'
      }
    } else if (typeof prices.telemed === 'number' && typeof prices.telemedMedtochka !== 'number') {
      scheduleType = 'lpu'
    } else if (typeof prices.telemed !== 'number' && typeof prices.telemedMedtochka === 'number') {
      scheduleType = 'mt'
    }

    return scheduleType
  },
}
