import type { LpuAddressList, PriceList } from 'components/common/DoctorPageLpuList/types'

type Workplace = LpuAddressList[number]['workplaces'][number]

export default function getPriceList(workplace: Workplace | undefined): PriceList {
  if (!workplace) {
    return {
      price: null,
      priceHome: null,
      priceOnline: null,
      priceMedtochkaTelemed: null,
      priceClub: null,
    }
  }

  return {
    price: workplace.price,
    priceHome: workplace.price_go,
    priceOnline: workplace.appointment_telemed
      && (workplace.appointment_direct || !window.FEATURE_FLAGS.pd_medtochka_telemed)
      && workplace.metric?.has_slots_telemed_in_clinic
      ? workplace.pd_price_online
      : null,
    priceMedtochkaTelemed: workplace.appointment_telemed
      && workplace.enable_appointment_telemed_in_medtochka
      && workplace.metric?.has_slots_telemed_in_medtochka
      ? workplace.mt_price_online
      : null,
    priceClub: workplace.club_price,
  }
}
