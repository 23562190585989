export type DoctorWorkplace = {
  id: number
  isInClub: boolean
  clubDiscount: number
  speciality: {
    id: number
    name: string
  }
  priceList: {
    price: number | null
    priceHome: number | null
    priceOnline: number | null
    priceMedtochkaTelemed: number | null
    priceClub: number | null
    priceClubLpu: number | null
    priceClubCoupon: number | null
  }
  age: {
    min: number | null
    max: number | null
  }
  isShowPhoneOnly: boolean
  isRequest: boolean
}

type DoctorData = {
  id: number | null
  fullName: string | null
  imageSrc: string | null
  experience: string | null
  category: string | null
  science: string | null
  pageUrl: string | null
  workplaces: DoctorWorkplace[]
}

export const getDoctorData = (): DoctorData => ({
  id: null,
  fullName: null,
  imageSrc: null,
  experience: null,
  category: null,
  science: null,
  pageUrl: null,
  workplaces: [],
})
