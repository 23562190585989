var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEmptySlotList
    ? _c("CalendarStub")
    : _c(
        "div",
        {
          staticClass: "b-appointment-calendar",
          attrs: { "data-lpu": _vm.lpuId, "data-doctor": _vm.doctorId },
        },
        [
          _c("div", { staticClass: "b-appointment-calendar__day-selector" }, [
            _c(
              "div",
              {
                staticClass: "b-appointment-calendar__btn",
                class: {
                  "b-appointment-calendar__btn_disabled": _vm.isFirstDay,
                },
                attrs: { "data-qa": _vm.QALocators["button-prev"] },
                on: { click: _vm.prevDay },
              },
              [
                _c("span", {
                  staticClass:
                    "b-appointment-calendar__next-icon ui-icon-arrow-left ui-icon_fz_smaller",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "b-appointment-calendar__cur-day" }, [
              _vm._v("\n      " + _vm._s(_vm.dateName) + "\n    "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "b-appointment-calendar__btn",
                class: {
                  "b-appointment-calendar__btn_disabled": _vm.isLastDay,
                },
                attrs: { "data-qa": _vm.QALocators["button-next"] },
                on: { click: _vm.nextDay },
              },
              [
                _c("span", {
                  staticClass:
                    "b-appointment-calendar__next-icon ui-icon-arrow-right ui-icon_fz_smaller",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("AppCalendarSlotSet", {
            attrs: {
              "doctor-id": _vm.doctorId,
              "base-slot-set": _vm.slots,
              "selected-slot": _vm.selectedSlot,
              "selected-date": _vm.slotSelectedDate,
              "expanded-state": _vm.expanded.state,
              "dynamic-slot-set": _vm.slotsDataObj,
            },
            on: _vm._d({}, [
              _vm.eventClickSlot,
              _vm.handleClickOnTime,
              _vm.eventClickExpand,
              _vm.onClickExpandBtn,
              _vm.eventClickCollapse,
              _vm.onClickCollapseBtn,
            ]),
          }),
          _vm._v(" "),
          _c("AppCalendarInvasiveModal", {
            attrs: { "is-visible": _vm.isVisibleInvasiveModal },
            on: {
              "invasive-modal:click-close": _vm.handleInvasiveModalClose,
              "invasive-modal:click-continue": _vm.handleInvasiveModalContinue,
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }