import { format } from 'date-fns'
import { getTimezoneOffsetInHours } from 'utils'
import { getItemDataWithParent } from 'modules/Schedule/functions/helpers'

import {
  getDoctorRequestInterface,
  getDoctorOnlineRequestInterface,
} from 'modules/Schedule/models'

/**
 * @description
 * Собирает данные для запроса за расписанием для 1 врача.
 * Если timezone пользователя и сервера совпадают - запроса быть не должно.
 *
 *
 * @param { Object } opts
 * @param { Object } opts.data - данные 'ScheduleData'
 * @param { Boolean } opts.isOnlineSelected - является ли выбранным таб 'Онлайн'
 * @param { HTMLElement } opts.target - узел, который послужил триггером события
 * @param {import('www/api/apiScheduleTelemed.types').ScheduleType} opts.scheduleType
 *
 * @return { Object | void } - объект в формате 'getDoctorRequestInterface' | 'getDoctorOnlineRequestInterface' если данные успешной собраны.
 * Если не найдено данных для врача или timezone пользователя и сервера совпадает - undefined, так как запрос в этих случаях не нужен
 * */

const getDoctorsRequestDataSingle = ({
  data,
  isOnlineSelected,
  isTelemedMedtochkaSelected,
  scheduleType,
  target,
}) => {
  const {
    dateStart,
    timedelta,
    doctorsLpus,
  } = data.storage

  const isAnyTelemed = isOnlineSelected || isTelemedMedtochkaSelected
  const isNewTelemed = isAnyTelemed && window.FEATURE_FLAGS.pd_medtochka_telemed
  const baseData = isAnyTelemed ? getDoctorOnlineRequestInterface() : getDoctorRequestInterface()

  const [, itemData] = getItemDataWithParent({ data, target })

  const chosenDoctorId = itemData.doctorId
  const { coverNode } = itemData

  const chosenLpuId = Number(
    coverNode.dataset.wpBlockId
      || coverNode.querySelector('option:checked')?.dataset.lpu, // ToDo schedule-important избавиться от привязки к DOM дереву
  )

  const doctorHasSlots = doctorsLpus
    .find(({ has_slots: hasSlots, doctor_id: doctorId, lpu_id: lpuId }) => (
      (typeof hasSlots === 'undefined' ? true : hasSlots) // на странице врачей клиники поле 'has_slots' отсутствует
      && doctorId === chosenDoctorId
      && lpuId === chosenLpuId
    ))

  if (!doctorHasSlots) {
    return
  }

  const lpuTimedelta = Number(doctorHasSlots.lpu_timedelta)
  const userTimedelta = getTimezoneOffsetInHours()
  const isUserTimedeltaEqualLpu = userTimedelta === lpuTimedelta

  if (!window.FEATURE_FLAGS.pd_medtochka_telemed && isUserTimedeltaEqualLpu) {
    return
  }

  if (!isNewTelemed) {
    baseData.doctors_lpus = [doctorHasSlots]
  }

  if (isAnyTelemed) {
    if (isNewTelemed) {
      baseData.user_start_date = format(new Date(), 'yyyy-MM-dd')
      baseData.user_timezone = userTimedelta
      baseData.lpu_params = [{
        lpu_id: doctorHasSlots.lpu_id,
        schedule_params: [{
          doctor_id: doctorHasSlots.doctor_id,
          type_schedule: scheduleType,
        }],
      }]
    } else {
      baseData.dt_start = format(new Date() || 0, 'yyyy-MM-dd') // оператор '||' нужен, чтоб IDE не ругался
      baseData.user_timedelta = userTimedelta
      baseData.lpu_timedelta = [[doctorHasSlots.lpu_id, doctorHasSlots.lpu_timedelta]]
    }
  } else {
    baseData.dt_start = dateStart
    baseData.town_timedelta = timedelta
  }

  return baseData
}

export default getDoctorsRequestDataSingle
