<template>
  <component
    :is="MainComponent"
    v-model="isVisible"
    :width="isMobile ? undefined : 360"
    scrollable
  >
    <VCard
      class="ui-kit-color-text"
      :max-height="isMobile ? undefined : 500"
    >
      <div v-if="!isMobile">
        <div class="d-flex align-center pa-4">
          <VIcon
            class="mr-4"
            color="uiKitIconSecondary"
            size="24"
            @click="isVisible = false"
          >
            ui-icon-close-not-a-circle
          </VIcon>
          <div class="ui-text ui-text_h6 ui-kit-color-text">
            Дарим скидку 300&nbsp;₽ за&nbsp;честный отзыв
          </div>
        </div>
        <VDivider />
      </div>
      <div
        v-if="isMobile"
        class="d-flex align-center px-4 pt-6 mb-2"
      >
        <div class="ui-text ui-text_h6 ui-kit-color-text">
          Дарим скидку 300&nbsp;₽ за&nbsp;честный отзыв
        </div>
      </div>
      <VCardText
        class="px-0"
        :class="isMobile ? 'py-0' : 'py-4'"
      >
        <div class="ui-text ui-text_body-1 ui-kit-color-text-secondary px-4 mb-2">
          Важно любое ваше мнение: позитивное или негативное.
        </div>
        <template v-if="openEventPayload.doctorReviewUrl && openEventPayload.lpuReviewUrl">
          <div class="ui-text ui-text_subtitle-1 ui-kit-color-text px-4 mt-5">
            Можно оставить отзыв
          </div>
          <a
            v-ripple
            class="d-flex align-center text-decoration-none px-4 py-3 mt-2"
            :href="openEventPayload.doctorReviewUrl"
          >
            <VIcon
              class="mr-8"
              color="uiKitIconSecondary"
            >
              ui-icon-doctor
            </VIcon>
            <div class="ui-text ui-text_body-1">
              О враче клиники
            </div>
            <VSpacer />
            <VIcon color="uiKitIconSecondary">
              ui-icon-arrow-right
            </VIcon>
          </a>
          <a
            v-ripple
            class="d-flex align-center text-decoration-none px-4 py-3 mt-2"
            :href="openEventPayload.lpuReviewUrl"
          >
            <VIcon
              class="mr-8"
              color="uiKitIconSecondary"
            >
              ui-icon-clinic
            </VIcon>
            <div class="ui-text ui-text_body-1">
              О клинике
            </div>
            <VSpacer />
            <VIcon color="uiKitIconSecondary">
              ui-icon-arrow-right
            </VIcon>
          </a>
        </template>
        <template v-if="openEventPayload.lpuList">
          <div class="ui-text ui-text_subtitle-1 ui-kit-color-text px-4 mt-5 mb-2">
            Можно оставить отзыв о&nbsp;приёме в&nbsp;клинике
          </div>
          <a
            v-for="lpu in openEventPayload.lpuList"
            :key="lpu.reviewUrl"
            v-ripple
            class="d-flex align-center text-decoration-none px-4 py-2"
            :href="lpu.reviewUrl"
          >
            <div class="mr-4">
              <div class="ui-text ui-text_body-1">
                {{ lpu.name }}
              </div>
              <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary">
                {{ lpu.address }}
              </div>
            </div>
            <VSpacer />
            <VIcon color="uiKitIconSecondary">
              ui-icon-arrow-right
            </VIcon>
          </a>
        </template>
      </VCardText>
      <VSheet
        class=""
        :class="isMobile ? 'px-4 pb-4 mt-5' : 'pa-2'"
        :elevation="isMobile || !openEventPayload.lpuList ? 0 : 8"
      >
        <VBtn
          class="ui-kit-color-primary"
          color="uiKitBgPrimary"
          depressed
          block
          @click="handleClickButton"
        >
          Хорошо
        </VBtn>
      </VSheet>
    </VCard>
  </component>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { VBottomSheet, VDialog } from 'vuetify/lib'

type OpenEventPayload = {
  doctorReviewUrl?: string
  lpuReviewUrl?: string
  lpuList?: Array<{
    name: string
    address: string
    reviewUrl: string
  }>
}

const isMobile = window.MOBILE_VERSION
const MainComponent = isMobile ? VBottomSheet : VDialog

const isVisible = ref(false)
const openEventPayload = ref<OpenEventPayload>({})

onMounted(() => {
  // eslint-disable-next-line no-undef
  window.addEventListener('review-power-info:open', ({ detail: payload }: CustomEventInit<OpenEventPayload>) => {
    if (payload) {
      isVisible.value = true
      openEventPayload.value = payload
    }
  })
})

function handleClickButton() {
  isVisible.value = false
}
</script>
