<template>
  <div
    class="award-modal-content"
    data-qa="award_modal_content"
  >
    <img
      class="award-modal-content__img mb-4"
      :src="modalData.image.src"
      :alt="modalData.image.alt"
      width="408"
      height="234"
    >
    <div
      v-for="(event, index) in modalData.events"
      :key="event.date"
      :class="{ 'mb-6': index !== modalData.events.length - 1 }"
    >
      <div
        v-if="event.title"
        class="ui-text ui-text_subtitle-1 mb-1"
      >
        {{ event.title }}
      </div>
      <div class="d-flex align-items-center mb-4">
        <div
          class="ui-text mr-2"
          :class="{
            'ui-text_color_gold': isDarkMode,
            'ui-kit-color-text-secondary': !isDarkMode,
            'ui-icon-check-circle': event.isIconCheckCircle,
            'ui-icon-clock': !event.isIconCheckCircle,
          }"
        />
        <div class="ui-text ui-text_body-1">
          {{ event.date }}
        </div>
      </div>
      <p
        v-for="(paragraph, i) in event.paragraphs"
        :key="i"
        class="ui-text ui-text_body-1 mb-4"
      >
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<script>
import AwardCommonData from 'components/common/Award/mixins/AwardCommonData'

export default {
  name: 'AwardModalContent',
  mixins: [
    AwardCommonData,
  ],
  props: {
    modalData: {
      type: Object,
      default: () => ({}),
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.award-modal-content {
  &__img {
    display: block;
    margin: 0 auto;
    height: 100%;
    max-width: 100%;
    max-height: 234px;
    border-radius: 6% / 10%; // Примерно соответствует "border-radius: 24px" при ширине 408px и высоте 234px
  }
}
</style>
