<template>
  <div>
    <VSelect
      :value="1"
      :label="label"
      append-icon="ui-icon-arrow-down"
      :items="[1]"
      :data-selected-speciality-id="selectedItem.workplace?.spec.id"
      :menu-props="{
        disabled: true,
      }"
      outlined
      hide-details
      @click="openDialog"
    >
      <template #selection>
        <div class="text-break">
          <div v-if="selectedItem.isWorkplace">
            {{ selectedItem.workplace?.spec.name }} - приём
          </div>
          <div v-else>
            {{ selectedItem.service?.name }}
          </div>
          <div class="ui-text ui-text_body-2 uiKitTextSecondary--text">
            <span v-if="selectedItem.isWorkplace">{{ formattedSelectPrice }}</span>
            <span v-else>{{ selectedItem.price }}</span>
            <template v-if="selectedItem.agesText">
              <span class="uiKitBgGray80--text mx-1">•</span>
              {{ selectedItem.agesText }}
            </template>
          </div>
        </div>
      </template>
    </VSelect>
    <WorkplaceOrServiceDialog
      v-model="isDialogVisible"
      :lpu-id="lpuId"
      @item-selected="handleSelectItem"
    />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  nextTick,
  ref,
} from 'vue'
import { storeToRefs } from 'pinia'
import { getFormattedPrice } from 'utils'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import { useDoctorPageLpuListStore } from 'components/common/DoctorPageLpuList/stores'
// eslint-disable-next-line import/extensions
import WorkplaceOrServiceDialog from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceDialog/WorkplaceOrServiceDialog.vue'
import { getPriceList } from 'components/common/DoctorPageLpuList/functions'
import type { PlaceType } from 'components/common/DoctorPriceBlock/types'
import type { ScheduleType } from 'www/api/apiScheduleTelemed.types'
import type { SelectWorkplaceOrServiceItem } from 'components/common/DoctorPageLpuList/types'

const props = defineProps<{
  lpuId: number
  label?: string
}>()

const isDialogVisible = ref(false)

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuId)()
const { selectedItem, scheduleType } = storeToRefs(doctorAppointmentCardStore)
const { requestCalendarSlots, resetSlots } = doctorAppointmentCardStore

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType } = storeToRefs(doctorPageLpuListStore)

const formattedSelectPrice = computed(() => {
  const priceList = getPriceList(selectedItem.value.workplace)

  const { priceOnline, priceMedtochkaTelemed, priceClub } = priceList

  let price

  switch (filterType.value) {
    case 'clinic':
      price = priceList[typeof priceClub === 'number' ? 'priceClub' : 'price']
      break
    case 'home':
      price = priceList.priceHome
      break
    case 'telemed':
      if (window.FEATURE_FLAGS.pd_medtochka_telemed) {
        price = Math.min(...[priceOnline ?? Infinity, priceMedtochkaTelemed ?? Infinity])
      } else {
        price = priceList.priceOnline
      }

      break
  }

  if (!price) {
    return ''
  }

  return getFormattedPrice(price, {
    prefix: filterType.value === 'home'
      || (filterType.value === 'clinic' && typeof priceClub === 'number')
      || (
        window.FEATURE_FLAGS.pd_medtochka_telemed
        && filterType.value === 'telemed'
        && typeof priceOnline === 'number'
        && typeof priceMedtochkaTelemed === 'number'
        && priceOnline !== priceMedtochkaTelemed
      )
      ? 'от'
      : '',
  })
})

function openDialog() {
  isDialogVisible.value = true
}

async function afterNewItemSelected(prevFilterType: PlaceType, prevScheduleType: ScheduleType) {
  if (
    prevFilterType === 'telemed'
      && filterType.value === 'telemed'
      && prevScheduleType !== scheduleType.value
  ) {
    await requestCalendarSlots()
  }
}

async function handleSelectItem(item: SelectWorkplaceOrServiceItem) {
  const prevSelectedItem = selectedItem.value
  const prevFilterType = filterType.value
  const prevScheduleType = scheduleType.value

  selectedItem.value = item

  if (window.FEATURE_FLAGS.pd_medtochka_telemed) {
    /**
     * nextTick нужен, чтобы обновились вычисляемые значения, от которых зависит запрос за слотами
     */
    await nextTick()
    await afterNewItemSelected(prevFilterType, prevScheduleType)
  }

  if (filterType.value === 'telemed' || (item.isWorkplace && prevSelectedItem.isWorkplace)) {
    return
  }

  resetSlots()
  await requestCalendarSlots()
}
</script>

<style scoped lang="scss">
// Потому что есть глобальные стили с селектором `#app input`, которые ломают отображение
:deep(input) {
  height: 0 !important;
}
</style>
