import { getBooleanFromString } from 'utils'
import { APPOINTMENT_TYPES } from 'constants'

const NAMESPACE = {
  activeTabValue: 'data-active-tab-value',
  lpuInfoClassNameActive: 'b-doctor-card__lpu-info_active',
  lpuInfoSelectedClassName: 'b-doctor-card__selected-lpu-info',
  selectorAppointmentTypeTab: '.appointment-tab-list',
}

/**
 * @description
 *
 * Возвращает значение выбранного таба типа приёма(клуб, телемед, на дом и тд)
 * Используется только для передачи get параметра type во время редиректа в форму записи со страницы врача/списка врачей(где есть табы с типом приёма)
 *
 * @param { Object } opts
 * @param { Object } opts.target - узел, по которому произошёл клик
 * @param { Object } opts.dataItem - поле 'dataItem' интерфейса 'getModuleOptionsInterface'
 *
 * @return { String } - выбранный тип или пустая строка, если соответствующего значения не найдено
 *
 * ToDo schedule-important
 * */

const getVisitType = ({ target, dataItem }) => {
  const defaultResult = ''
  const filterTabsElement = document.querySelector('[data-chosen-appointment-type-filter]')

  if (filterTabsElement) {
    return filterTabsElement.getAttribute('data-chosen-appointment-type-filter') || defaultResult
  }

  if (!window.FEATURE_FLAGS.pd_medtochka_telemed) {
    const isTownOnline = document.querySelector('[data-is-town-online]')?.getAttribute('data-is-town-online')

    if (getBooleanFromString(isTownOnline)) {
      return APPOINTMENT_TYPES.telemed
    }
  }

  if (!(target && dataItem)) {
    return defaultResult
  }

  const parentContainer = target?.closest(`[${dataItem}]`)

  if (!parentContainer) {
    return defaultResult
  }

  const selectedWpTabListMobile = [...parentContainer.querySelectorAll(`.${NAMESPACE.lpuInfoSelectedClassName}`)]
    .filter(item => getComputedStyle(item).display !== 'none')[0]
  const selectedWpTabListDesktop = parentContainer.querySelector(`.${NAMESPACE.lpuInfoClassNameActive}`)
  const activeTabWrapper = selectedWpTabListMobile || selectedWpTabListDesktop || parentContainer

  if (!activeTabWrapper) {
    return defaultResult
  }

  const tabsWrapper = activeTabWrapper.querySelector(NAMESPACE.selectorAppointmentTypeTab)
  const visitType = tabsWrapper?.getAttribute(NAMESPACE.activeTabValue)
        || activeTabWrapper.querySelector(`[${NAMESPACE.activeTabValue}]`)?.getAttribute(NAMESPACE.activeTabValue)

  return visitType || defaultResult
}

export default getVisitType
