<template>
  <div
    data-qa="doctor-price-block-item-list"
    :class="minimal ? undefined : [isClubItemVisible ? 'pt-5' : 'pt-4', 'pb-3']"
    :data-club-switch-block="clubSwitchState"
    :data-active-tab-value="redirectValue"
  >
    <div class="d-flex">
      <DoctorPriceBlockItem
        v-if="isClubItemVisible"
        class="mr-2"
        :subtitle="!minimal ? 'Клубная цена' : undefined"
        :price="clubPrice"
        :active="isClubItemActive"
        club
        @click.native="changeClubState(true)"
      />
      <DoctorPriceBlockItem
        v-if="isDefaultTabVisible"
        :class="{
          'order-1 ml-2': isTelemedTabsOrderReversed,
        }"
        :price="price"
        :subtitle="!minimal && price ? priceSubtitle : undefined"
        :active="(!isClubItemActive || !isClubItemVisible) && !isTelemedMedtochkaItemActive"
        :price-prefix="pricePrefix"
        @click.native="handleClickDefaultTab"
      />
      <DoctorPriceBlockItem
        v-if="isTelemedMedtochkaItemVisible"
        :class="{
          'ml-2': isDefaultTabVisible && !isTelemedTabsOrderReversed,
          'order-0': isTelemedTabsOrderReversed,
        }"
        :price="telemedMedtochkaPrice"
        :subtitle="!minimal && telemedMedtochkaPrice ? priceSubtitle : undefined"
        :active="isTelemedMedtochkaItemActive"
        :price-prefix="pricePrefix"
        @click.native="changeTelemedMedtochkaState(true)"
      />
    </div>
    <template v-if="isClubItemVisible">
      <div
        class="d-flex align-center ui-kit-color-error cursor-pointer mt-3"
        @click="handleClickShowClubInfoModal"
      >
        <img
          src="/static/_v1/pd/icons/ui-kit/club/club-percent.svg"
          alt="Иконка Процент Клуба"
          width="20"
          height="20"
        >
        <div class="ui-text ui-text_body-2 ui-text_underline ml-2">
          {{ clubInfoText }}
        </div>
      </div>
      <ClubDiscountInfoBottomSheet
        v-model="isClubInfoVisible"
        :full-price="/* @ts-ignore */price"
        :club-offer-data="clubOfferData"
      />
    </template>
    <div
      v-else-if="isTelemedMedtochkaItemVisible && isDefaultTabVisible"
      class="d-flex align-center ui-text ui-text_body-1 ui-kit-color-text-secondary mt-2"
    >
      <VIcon
        class="mr-2"
        color="uiKitIconSecondary"
      >
        ui-icon-circle-info
      </VIcon>
      Доступное время зависит от цены
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { getFormattedPrice } from 'utils'
import { REDIRECT_VALUES } from 'constants/redirectValues'
import { DoctorPriceBlockItem } from 'components/common/DoctorPriceBlock/components'
import { APPOINTMENT_TYPES } from 'constants/appointmentTypes'
// eslint-disable-next-line import/extensions
import ClubDiscountInfoBottomSheet from 'components/common/Club/components/core/ClubDiscount/components/common/ClubDiscountInfoBottomSheet.vue'
import { CLUB_DISCOUNT } from 'components/common/Club/constants'

const props = defineProps<{
  pageType: 'clinic' | 'telemed' | 'home'
  price?: number | null
  clubPrice?: number | null
  telemedMedtochkaPrice?: number | null
  specialitySelected?: boolean
  minimal?: boolean
  selectedTab?: 'default' | 'medtochka' | 'club'
}>()

const emit = defineEmits(['update:redirect-value', 'update:telemed-medtochka-state'])

const isClubInfoVisible = ref(false)
const isClubItemVisible = computed(() => props.pageType === 'clinic' && typeof props.clubPrice === 'number')
const isClubItemActive = ref(true)

const isTelemedMedtochkaItemVisible = computed(
  () => props.pageType === 'telemed'
    && window.FEATURE_FLAGS.pd_medtochka_telemed
    && typeof props.telemedMedtochkaPrice === 'number'
    && (props.specialitySelected || !props.price || props.telemedMedtochkaPrice < props.price)
    && props.price !== props.telemedMedtochkaPrice,
)

const isDefaultTabVisible = computed(
  () => typeof props.price === 'number'
    && (
      !window.FEATURE_FLAGS.pd_medtochka_telemed
        || (props.specialitySelected || !props.telemedMedtochkaPrice || props.price <= props.telemedMedtochkaPrice)
    ),
)

const isInitialTabState = ref(!props.selectedTab)

const isTelemedMedtochkaItemActivated = ref(props.selectedTab === 'medtochka')
const isTelemedTabsOrderReversed = computed(
  () => isTelemedMedtochkaItemVisible.value
    && (props.price
      && props.telemedMedtochkaPrice
      && props.telemedMedtochkaPrice < props.price
    ),
)
const isTelemedMedtochkaItemActive = computed(
  () => isTelemedMedtochkaItemVisible.value
    && (isTelemedMedtochkaItemActivated.value
      || !isDefaultTabVisible.value
      || (isTelemedTabsOrderReversed.value && isInitialTabState.value)
    ),
)

const clubSwitchState = computed(() => {
  if (props.pageType !== 'clinic') {
    return undefined
  }

  return isClubItemActive.value ? APPOINTMENT_TYPES.club : APPOINTMENT_TYPES.lpu
})

const clubInfoText = computed(() => {
  if (!props.price || !props.clubPrice) {
    return ''
  }

  return isClubItemActive.value
    ? 'Как работает клубная цена'
    : `Клубная цена ниже на ${getFormattedPrice(props.price - props.clubPrice)}`
})

const clubOfferData = computed(() => {
  if (typeof props.clubPrice !== 'number') {
    return {}
  }

  const couponPrice = Math.floor(props.clubPrice / 2)
  const cardPrice = window.USER.club.isInClub ? 0 : window.USER.club.cardNextPrice

  return {
    lpuPrice: props.clubPrice - couponPrice,
    couponPrice,
    cardDateEnd: window.USER.club.cardDateEnd,
    cardPrice,
    totalPrice: props.clubPrice + (cardPrice || 0),
    clubDiscount: CLUB_DISCOUNT,
  }
})

const priceSubtitle = computed(() => {
  switch (props.pageType) {
    case 'clinic':
      return 'Оплата в клинике'
    case 'telemed':
      return 'Оплата на сайте'
  }

  return ''
})

const pricePrefix = computed(() => {
  if (props.specialitySelected) {
    return props.pageType === 'home'
  }

  return props.pageType !== 'clinic'
})

const redirectValue = computed<typeof REDIRECT_VALUES[keyof typeof REDIRECT_VALUES]>(() => {
  if (isClubItemActive.value && isClubItemVisible.value) {
    return REDIRECT_VALUES.club
  }

  if (isTelemedMedtochkaItemActive.value) {
    return REDIRECT_VALUES.telemedMedtochka
  }

  if (props.pageType === 'clinic') {
    return REDIRECT_VALUES.default
  }

  return REDIRECT_VALUES[props.pageType]
})

watch(redirectValue, () => {
  emit('update:redirect-value', redirectValue.value)
})

emit('update:redirect-value', redirectValue.value)

function changeClubState(state: boolean) {
  isClubItemActive.value = state
  isInitialTabState.value = false
}

function handleClickShowClubInfoModal() {
  isClubInfoVisible.value = true
}

function changeTelemedMedtochkaState(state: boolean) {
  if (isTelemedMedtochkaItemActive.value === state) {
    return
  }

  isTelemedMedtochkaItemActivated.value = state
  isInitialTabState.value = false
  emit('update:telemed-medtochka-state', state)
}

function handleClickDefaultTab() {
  switch (props.pageType) {
    case 'clinic':
      changeClubState(false)
      break
    case 'telemed':
      changeTelemedMedtochkaState(false)
      break
  }
}
</script>
