import { DAYS, ONLY_FREE } from 'www/modules/Schedule/constants'

/**
 * @description
 * Интерфейсы соответствуют объектам, которые ожидаются на backend.
 * Если передаваемые данные в запросе не соответствуют полям интерфейса - будет ошибка валидации.
 * */

export const getLpuRequestInterface = () => ({ // запрос за календарями с выбором специальности(список клиник)
  days: DAYS,
  dt_start: '',
  town_timedelta: '',
  lpus_specialities: [],
})

export const getDoctorRequestInterface = () => ({ // запрос за календарями врачей
  days: DAYS,
  dt_start: '',
  doctors_lpus: [],
  town_timedelta: null,
})

export const getDoctorOnlineRequestInterface = () => (
  window.FEATURE_FLAGS.pd_medtochka_telemed
    ? /** @type {import('www/api/apiScheduleTelemed.types').ApiScheduleTelemedParamsPost} */ {
      user_start_date: '',
      user_timezone: null,
      days: DAYS,
      only_free: true,
      lpu_params: [],
    }
    : { // запрос за календарями врачей, которые принимают online(телемед)
      days: DAYS,
      dt_start: '',
      doctors_lpus: [],
      lpu_timedelta: [],
      user_timedelta: null,
    }
)

export const getServicesRequestInterface = () => ({ // запрос за календарями на услугах(запись на синоним)
  days: DAYS,
  services: [],
  day_start: '',
  only_free: ONLY_FREE,
  town_timedelta: null,
})
