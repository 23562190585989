import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import { dispatchCustomEventGlobally } from 'utils'
import VideoCard from 'components/common/VideoCard/VideoCard'

const mountElements = [...document.querySelectorAll('.video-card-vue')]

if (mountElements.length) {
  mountElements.forEach(node => {
    const VueInstance = new Vue({
      vuetify,
      components: { VideoCard },
    })

    VueInstance.$mount(node)
  })

  document.addEventListener('click', event => {
    try {
      if (!event.target) {
        return
      }

      const desktopButton = event.target.closest('.b-doctor-details__video-play')
      const mobileButton = event.target.closest('.b-doctor-info-card__video-play')

      if (desktopButton || mobileButton) {
        dispatchCustomEventGlobally('click-video-preview-image')
      }
    } catch {} // eslint-disable-line
  })
}
