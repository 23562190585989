<template>
  <component
    :is="MainComponent"
    v-model="isVisible"
    :width="isMobile ? undefined : 360"
  >
    <VCard class="ui-kit-color-text">
      <div v-if="!isMobile">
        <div class="d-flex align-center px-6 py-4">
          <VIcon
            class="mr-6"
            color="uiKitIconSecondary"
            size="24"
            @click="isVisible = false"
          >
            ui-icon-close-not-a-circle
          </VIcon>
          <div class="ui-text ui-text_h6 ui-kit-color-text">
            {{ title }}
          </div>
        </div>
        <VDivider />
      </div>
      <div
        class="px-4"
        :class="[isMobile ? 'py-6' : 'py-4']"
      >
        <div
          v-if="isMobile"
          class="d-flex align-center mb-2"
        >
          <VIcon
            color="uiKitSuccess"
            size="24"
          >
            ui-icon-shield-checkmark-40
          </VIcon>
          <div class="ui-text ui-text_h6 ui-kit-color-text-success ml-4">
            {{ title }}
          </div>
        </div>
        <div class="ui-text ui-text_body-1 ui-kit-color-text-secondary mb-2">
          <template v-if="isTypeLicense">
            Лицензия клиники в реестре Росздравнадзора актуальна:
            <br>{{ documents.licenseNumber }}
          </template>
          <template v-else>
            Специалист предоставил документы, а&nbsp;мы&nbsp;проверили их&nbsp;достоверность
          </template>
        </div>
        <div
          v-if="documents.isEducationConfirmed"
          class="d-flex py-3"
        >
          <VIcon
            color="uiKitTextSuccess"
            size="24"
          >
            ui-icon-check-circle
          </VIcon>
          <div class="ui-text ui-text_body-1 ml-4">
            Диплом о базовом образовании
          </div>
        </div>
        <div
          v-if="documents.isCategoryConfirmed"
          class="d-flex py-2"
        >
          <VIcon
            v-if="documents.isCategoryValid && !documents.isCategoryExpiringSoon"
            color="uiKitTextSuccess"
            size="24"
          >
            ui-icon-check-circle
          </VIcon>
          <VIcon
            v-else
            color="uiKitTextError"
            size="24"
          >
            ui-icon-circle-warning
          </VIcon>
          <div class="ml-4">
            <div class="ui-text ui-text_body-1">
              Удостоверение о категории
            </div>
            <div
              v-if="!documents.isCategoryValid"
              class="ui-text ui-text_body-2 ui-kit-color-text-error"
            >
              Документ уже недействителен
            </div>
            <div
              v-else-if="documents.categoryExpiresDate"
              class="ui-text ui-text_body-2"
              :class="documents.isCategoryExpiringSoon ? 'ui-kit-color-text-error' : 'ui-kit-color-text-secondary'"
            >
              Действительно до {{ categoryExpiresDateFormatted }}
            </div>
          </div>
        </div>
        <div
          v-if="documents.isScienceConfirmed"
          class="d-flex py-3"
        >
          <VIcon
            color="uiKitTextSuccess"
            size="24"
          >
            ui-icon-check-circle
          </VIcon>
          <div class="ui-text ui-text_body-1 ml-4">
            {{ scienceText }}
          </div>
        </div>
        <VBtn
          class="ui-kit-color-primary mt-3"
          color="uiKitBgPrimary"
          depressed
          block
          @click="handleClickButton"
        >
          {{ documents.linkToDocuments ? 'Посмотреть документы' : 'Хорошо' }}
        </VBtn>
      </div>
    </VCard>
  </component>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { format } from 'date-fns'
import { VBottomSheet, VDialog } from 'vuetify/lib'

type Documents = {
  linkToDocuments?: string | null
  isEducationConfirmed?: boolean
  isCategoryConfirmed?: boolean
  categoryExpiresDate?: string
  isCategoryExpiringSoon?: boolean | null
  isCategoryValid?: boolean | null
  isScienceConfirmed?: boolean
  scienceDocumentType?: 1 | 2 | 3
  licenseNumber?: string
}

const isMobile = window.MOBILE_VERSION
const MainComponent = isMobile ? VBottomSheet : VDialog

const isVisible = ref(false)
const documents = ref<Documents>({})

const isTypeLicense = computed(() => Boolean(documents.value.licenseNumber))

const scienceText = computed(() => (
  documents.value.scienceDocumentType
    ? {
      1: 'Диплом кандидата наук',
      2: 'Диплом доктора наук',
      3: 'Аттестат профессора',
    }[documents.value.scienceDocumentType]
    : ''))

const title = computed(() => (isTypeLicense.value ? 'Лицензия проверена ' : 'Документы проверены'))

const categoryExpiresDateFormatted = computed(() => {
  if (!documents.value.categoryExpiresDate) {
    return ''
  }

  return format(new Date(documents.value.categoryExpiresDate), 'dd.MM.yy')
})

onMounted(() => {
  // eslint-disable-next-line no-undef
  window.addEventListener('documents-verified:open', ({ detail: payload }: CustomEventInit<Documents>) => {
    if (payload) {
      isVisible.value = true
      documents.value = payload
    }
  })
})

function handleClickButton() {
  isVisible.value = false

  if (documents.value.linkToDocuments) {
    window.location.assign(documents.value.linkToDocuments)
  }
}
</script>
