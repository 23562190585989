var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "VApp",
    { attrs: { "data-psych-doctor-information": "" } },
    [
      _c("VIcon", { on: { click: _setup.clickHandle } }, [
        _vm._v("\n    ui-icon-circle-info\n  "),
      ]),
      _vm._v(" "),
      _setup.isMobile
        ? _c(
            _setup.VBottomSheet,
            {
              model: {
                value: _setup.isActive,
                callback: function ($$v) {
                  _setup.isActive = $$v
                },
                expression: "isActive",
              },
            },
            [
              _c("VSheet", [
                _c(
                  "div",
                  { staticClass: "bottom-sheet-container px-4 py-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_setup.props.title) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _setup.props.subtitle
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_body-1 ui-kit-color-text-secondary",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_setup.props.subtitle) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-4 ui-text ui-text_body-1 ui-kit-color-text",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_setup.props.description) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "VBtn",
                      {
                        attrs: {
                          block: "",
                          color: "ui-kit-color-bg-primary",
                          elevation: "0",
                        },
                        on: { click: _setup.clickHandle },
                      },
                      [
                        _c("span", { staticClass: "ui-kit-color-primary" }, [
                          _vm._v("\n            Понятно\n          "),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c(
            _setup.VDialog,
            {
              attrs: { width: "456" },
              model: {
                value: _setup.isActive,
                callback: function ($$v) {
                  _setup.isActive = $$v
                },
                expression: "isActive",
              },
            },
            [
              _c(
                "VSheet",
                [
                  _c(
                    "VSheet",
                    {
                      staticClass: "pa-2 d-flex align-center",
                      attrs: { "min-height": "48" },
                    },
                    [
                      _c(
                        "VSheet",
                        { attrs: { width: "24", height: "24" } },
                        [
                          _c("VIcon", { on: { click: _setup.clickHandle } }, [
                            _vm._v(
                              "\n            ui-icon-close-not-a-circle\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-8 ui-text" }, [
                        _c(
                          "div",
                          { staticClass: "ui-text_h6 ui-kit-color-text" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_setup.props.title) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _setup.props.subtitle
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ui-text_body-2 ui-kit-color-text-secondary",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_setup.props.subtitle) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("VDivider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pa-6" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "ui-text ui-text_body-1 ui-kit-color-text-secondary",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_setup.props.description) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "VCardActions",
                        { staticClass: "pa-0 mt-6" },
                        [
                          _c("VSpacer"),
                          _vm._v(" "),
                          _c(
                            "VBtn",
                            {
                              attrs: { color: "primary", elevation: "0" },
                              on: { click: _setup.clickHandle },
                            },
                            [_vm._v("\n            Понятно\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }