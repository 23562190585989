import {
  BUTTON_CLOSE_POPUP_CLASS,
  BUTTON_DEPARTURE_ADDRESS_CLASS,
} from 'components/common/PopupMap/constants'

function getPlacemarkBalloonTemplate(opts) {
  const {
    name,
    phone,
    address,
    lpuHref,
  } = opts
  const phoneTemplate = phone
    ? `
            <a
                href="tel: +7${phone}"
                class="mt-6 text-decoration-none d-flex"
                data-qa="popup_map_phone_link"
            >
                <span class="ui-icon-phone-call ui-icon_fz_medium ui-text ui-kit-color-primary mr-4"></span>
                <div
                    class="ui-text ui-text_body-2 ui-kit-color-primary"
                    data-qa="popup_map_phone_lpu_text"
                >
                    +7 ${phone}
                </div>
            </a>
        `
    : ''

  const nameTemplate = lpuHref
    ? `
        <a
            class="ui-text ui-text_body-1 ui-kit-color-primary text-decoration-none"
            data-qa="popup_map_lpu_name"
            href="${lpuHref}"
        >
            ${name}
        </a>
      `
    : `
      <div
          class="ui-text ui-text_body-1 ui-kit-color-primary"
          data-qa="popup_map_lpu_name"
      >
          ${name}
      </div>
    `

  return `
        <div class="p-4">
            ${nameTemplate}
            <div class="mt-5 d-flex">
                <span class="ui-icon-map-pin ui-icon_fz_medium ui-text ui-kit-color-text-info mr-4"></span>
                <div
                    class="ui-text ui-text_body-2 ui-kit-color-text"
                    data-qa="popup_map_adderess_lpu_text"
                >
                    ${address}
                </div>
            </div>
            ${phoneTemplate}
            <button
                type="button"
                class="b-button b-button_light w-100 mt-5 ${BUTTON_DEPARTURE_ADDRESS_CLASS}"
                data-qa="popup_map_plot_route_button"
            >
                <span class="ui-text ui-text_button ui-kit-color-primary">Проложить маршрут</span>
            </button>
            <button
                type="button"
                class="b-button b-button_text w-100 mt-2 ${BUTTON_CLOSE_POPUP_CLASS}"
                data-qa="popup_map_close_button"
            >
                <span class="ui-text ui-kit-color-text ui-icon-close-not-a-circle mr-2"></span>
                <span class="ui-text ui-text_button ui-kit-color-text">Закрыть</span>
            </button>
        </div>
    `
}

export default getPlacemarkBalloonTemplate
