import { SOFT_VERSION } from 'components/common/SoftVersionIcon/constants'

function getListBenefits({ softVersion, isLpuPage }) {
  const isStandard = softVersion === SOFT_VERSION.standard
  const isPro = softVersion === SOFT_VERSION.pro

  const listBenefits = window.FEATURE_FLAGS.pd_change_os_standart
    ? [
      `может обновлять данные о ${isLpuPage ? 'врачах' : 'себе'}`,
      'может отвечать на отзывы',
      (isPro || isStandard) && isLpuPage ? 'может писать об акциях и скидках' : '',
      isPro || isStandard ? 'отслеживает эффективность своей страницы для пациентов' : '',
      isPro ? 'отключает рекламу на странице' : '',
    ]
    : [
      `может обновлять данные о ${isLpuPage ? 'врачах' : 'себе'}`,
      'может отвечать на отзывы',
      (isPro || isStandard) ? 'отключает рекламу на странице' : '',
      (isPro && isLpuPage) ? 'может писать об акциях и скидках' : '',
      (isPro) ? 'отслеживает эффективность своей страницы для пациентов' : '',
    ]

  return listBenefits.filter(n => n)
}

export default getListBenefits
