var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "award-modal-content",
      attrs: { "data-qa": "award_modal_content" },
    },
    [
      _c("img", {
        staticClass: "award-modal-content__img mb-4",
        attrs: {
          src: _vm.modalData.image.src,
          alt: _vm.modalData.image.alt,
          width: "408",
          height: "234",
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.modalData.events, function (event, index) {
        return _c(
          "div",
          {
            key: event.date,
            class: { "mb-6": index !== _vm.modalData.events.length - 1 },
          },
          [
            event.title
              ? _c("div", { staticClass: "ui-text ui-text_subtitle-1 mb-1" }, [
                  _vm._v("\n      " + _vm._s(event.title) + "\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex align-items-center mb-4" }, [
              _c("div", {
                staticClass: "ui-text mr-2",
                class: {
                  "ui-text_color_gold": _vm.isDarkMode,
                  "ui-kit-color-text-secondary": !_vm.isDarkMode,
                  "ui-icon-check-circle": event.isIconCheckCircle,
                  "ui-icon-clock": !event.isIconCheckCircle,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ui-text ui-text_body-1" }, [
                _vm._v("\n        " + _vm._s(event.date) + "\n      "),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(event.paragraphs, function (paragraph, i) {
              return _c(
                "p",
                { key: i, staticClass: "ui-text ui-text_body-1 mb-4" },
                [_vm._v("\n      " + _vm._s(paragraph) + "\n    ")]
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }