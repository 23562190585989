<template>
  <div class="slot-set">
    <div :class="containerClassList">
      <VBtn
        v-for="(currentSlot, index) in dynamicSlotSet[selectedDate]"
        :key="`${selectedDate}-${index}`"
        :data-doctor="doctorId"
        :data-qa="QALocators['button-slot']"
        :color="getSlotColor(currentSlot)"
        :class="getSlotClassList(currentSlot)"
        depressed
        height="40"
        min-width="initial"
        @click="handleClickSlot($event, currentSlot)"
      >
        <span :class="getTimeClassList(currentSlot)">
          {{ currentSlot.time }}
        </span>
      </VBtn>
      <VBtn
        v-if="hasExpandButton"
        :color="vuetifyColors.uiKitBgPrimary"
        :data-qa="QALocators['button-expand']"
        depressed
        height="40"
        min-width="initial"
        :class="getSlotClassList()"
        @click="handleClickExpand"
      >
        <VIcon
          :size="20"
          class="slot-set__toggle-icon"
        >
          ui-icon-arrow-down
        </VIcon>
      </VBtn>
    </div>
    <VBtn
      v-if="expandedState"
      text
      block
      :data-qa="QALocators['button-collapse']"
      :color="vuetifyColors.primary"
      @click="handleClickCollapse"
    >
      <VIcon
        :size="20"
        class="slot-set__toggle-icon mr-2"
      >
        ui-icon-arrow-up
      </VIcon>
      Свернуть
    </VBtn>
  </div>
</template>

<script>
import { isFeatureFlagEnabledHTML } from 'utils'
import sendYaGoal from 'components/common/AppointmentPage/functions/sendYaGoal'
import { TOWN_ONLINE_GOALS } from 'yandexGoals'
import {
  AppCalendarEvents,
  AppCalendarDataQA,
} from 'components/common/AppointmentCalendar/mixins/index'

export default {
  name: 'AppCalendarSlotSet',
  mixins: [
    AppCalendarEvents,
    AppCalendarDataQA,
  ],
  props: {
    dynamicSlotSet: { // поле динамично, меняется в зависимости от выбранного слота(в родительском компоненте)
      type: Object,
      default: () => ({}),
    },
    baseSlotSet: { // поле статично, данные не меняются в процессе работы компонента
      type: Object,
      default: () => ({}),
    },
    selectedSlot: {
      type: Object,
      default: () => ({}),
    },
    selectedDate: {
      type: String,
      default: undefined,
    },
    doctorId: {
      type: Number,
      default: null,
    },
    expandedState: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    quantityRows: 2,
    isMobileDevice: window.MOBILE_VERSION,
    quantityCols: window.MOBILE_VERSION ? 4 : 5,
  }),
  computed: {
    vuetifyColors() {
      return {
        ...this.$vuetify.theme.themes.light,
      }
    },
    quantityStartedVisibleSlots() {
      return this.quantityRows * this.quantityCols
    },
    hasExpandButton() {
      if (!this.selectedDate) {
        return
      }

      const selectedBaseSlotSet = this.baseSlotSet[this.selectedDate]
      const hasMaxQuantitySlots = selectedBaseSlotSet.length > this.quantityStartedVisibleSlots
      const isLastSlot = selectedBaseSlotSet.length === this.dynamicSlotSet[this.selectedDate].length

      return hasMaxQuantitySlots && !isLastSlot
    },
    containerClassList() {
      let classListResult = 'slot-set__container'

      if (this.isMobileDevice) {
        classListResult += ' slot-set__container_mobile'
      }

      return classListResult
    },
  },
  methods: {
    handleClickSlot(event, currentSlot) {
      if (isFeatureFlagEnabledHTML({ dataAttr: 'data-is-town-online' })) {
        sendYaGoal(TOWN_ONLINE_GOALS.clickSlot)
      }

      this.$emit(this.eventClickSlot, event, currentSlot)
    },
    handleClickExpand() {
      this.$emit(this.eventClickExpand)
    },
    handleClickCollapse() {
      this.$emit(this.eventClickCollapse)
    },
    getIsSelectedSlot(currentSlot) {
      return this.selectedSlot.time === currentSlot.time
                && this.selectedDate === this.selectedSlot.day
    },
    getSlotColor(currentSlot) {
      const isSelectedSlot = this.getIsSelectedSlot(currentSlot)
      const resultColor = isSelectedSlot ? 'uiKitBgGray0' : 'primary'

      return this.vuetifyColors[resultColor]
    },
    getSlotClassList(currentSlot) {
      let classListResult = 'slot-set__item'

      if (currentSlot) {
        const isSelectedSlot = this.getIsSelectedSlot(currentSlot)

        if (isSelectedSlot) {
          classListResult += ' slot-set__item_selected'
        }
      }

      if (this.isMobileDevice) {
        classListResult += ' slot-set__item_mobile'
      }

      return classListResult
    },
    getTimeClassList(currentSlot) {
      let classListResult = 'ui-text '
      const isSelectedSlot = this.getIsSelectedSlot(currentSlot)

      classListResult += isSelectedSlot ? 'ui-kit-color-primary' : 'ui-kit-color-bg-gray-0'

      return classListResult
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.slot-set {
  box-sizing: border-box;

  &__container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: inherit;
    padding: 10px 10px 0;
  }

  &__container_mobile {
    padding: 16px 0 0;
  }

  &__item {
    border: 1px solid;
    box-sizing: inherit;
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(20% - 8px);
    transition: all 0.1s ease-in;
  }

  &__item_mobile {
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(25% - 8px);
  }

  &__item_mobile:nth-of-type(4n) {
    margin-right: 0;
  }

  &__item:not(&__item_mobile):nth-of-type(5n) {
    margin-right: 0;
  }

  &__item_selected {
    border-color: $ui-kit-primary !important; // перебиваю стили vuetify
  }

  &__toggle-icon {
    width: 20px;
    height: 20px;
    text-indent: initial;
    background-image: initial;
    color: $ui-kit-primary !important; // перебиваю стили vuetify
  }
}
</style>
