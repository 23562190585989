/* eslint-disable */
$(() => {
  let init = false

  $(document).on('initSlider', () => {
    if (window.location.hash.indexOf('#map_page') === -1 && window.location.hash.indexOf('#filters') === -1 && !init) {
      refreshSlider()
    }
  })

  $(document).on('refreshInit', () => {
    init = false
  })

  $(document).on('makeSlider', () => {
    if ($(event.target).hasClass('b-owl-slider')) {
      refreshSlider(event.target)
    }
  })

  function updateNav($slider, presentSlideIndex, slidesCount, items) {
    switch (presentSlideIndex) {
      case slidesCount - items: {
        $slider.find('.owl-next').toggle(false)
        $slider.find('.owl-prev').toggle(true)
        break
      }
      case 0: {
        $slider.find('.owl-next').toggle(true)
        $slider.find('.owl-prev').toggle(false)
        break
      }
      default: {
        $slider.find('.owl-next').toggle(true)
        $slider.find('.owl-prev').toggle(true)
        break
      }
    }
  }

  function refreshSlider(target) {
    const classesForDots = ['normal', 'aside', 'aside_small', 'aside_none', 'perm_none']// Существующие классы точек

    init = true

    // Задание точкам классов согласно массиву
    function renderDots($carousel, dotsClasses, dotsSelector) {
      const $dotsContainer = $carousel.find(dotsSelector || '.owl-dots')
      let $dots

      if ($dotsContainer.length) {
        $dots = $dotsContainer.find('.owl-dot')
      } else {
        $dots = $(dotsSelector).find('.owl-dot')
      }

      $dots.each((i, dot) => {
        const $dot = $(dot)

        classesForDots.forEach(dotClass => { if (dotClass !== 'perm_none') { $dot.removeClass(dotClass) } })
        $dot.addClass(dotsClasses[i])
      })
    }

    function triggerDataEvents($carousel) {
      if ($carousel.attr('data-events-to-trigger')) {
        const eventList = $carousel.data('events-to-trigger').split(' ')

        for (const event of eventList) {
          $carousel.trigger(event)
        }
      }
    }

    function slideNumberByHash($slider, hash) {
      let res = 0

      $slider.children().each((i, slide) => {
        if ($(slide).attr('data-hash') === hash) {
          res = i
          return false
        }
      })

      return res
    }

    let $elements

    if (target) {
      $elements = $(target)
    } else {
      $elements = $('.b-owl-slider')
    }

    $elements.each((i, el) => {
      // Слайдер, чтобы было видно кусок соседнего элемента
      const partSliderOpts = {
        items: 1,
        nav: false,
        autoWidth: true,
        navText: [''],

      }
      // Обычный слайдер 1 элемент - 1 страница(1 точка на элемент)
      // Количество слайдов можно менять с помощью атрибута - data-slides-count
      // Включить стрелки можно с помощью атрибута - data-slides-nav
      const sliderOpts = {
        items: 1,
        nav: false,
        navText: [''],
        slideBy: 1,
        dotsEach: 1,
        dots: true,
        URLhashListener: false,
      }

      const $el = $(el)
      const numOfMiddle = 3 // Количество больших точек
      const itemCount = $el.children().length
      let dotsClasses = null
      let lastIndex = 0

      // Устанавливается количество слайдов, если параметр отсутствует, то задаётся дефолтное значение 1
      if ($el.attr('data-slides-count')) {
        sliderOpts.items = parseInt($el.data('slides-count'))
      }

      // Атрибут, который включает отображение кнопок nav, если не указывать, то их не будет
      if ($el.attr('data-slides-nav')) {
        sliderOpts.nav = $el.data('slides-nav')
      }

      // Атрибут, который отвечает за содержание кнопок навигации (одна картинка на обе кнопки)
      if ($el.attr('data-nav-one-text')) {
        const text = $el.data('nav-one-text')

        sliderOpts.navText = [text, text]
      }

      // Атрибут, который включает/выключает отображение точек
      if ($el.attr('data-slides-dots')) {
        sliderOpts.dots = $el.data('slides-dots')
      }

      // Отступ справа
      if ($el.attr('data-margin-right')) {
        sliderOpts.margin = parseInt($el.data('margin-right'))
      }

      // Количество слайдов, которые будут пролистаны за одно нажатие стрелки
      if ($el.attr('data-slide-by')) {
        sliderOpts.slideBy = parseInt($el.data('slide-by'))
        sliderOpts.dotsEach = parseInt($el.data('slide-by'))
      }

      // Свой контейнер для точек (Селектор должен быть уникальным)
      if ($el.attr('data-dots-container')) {
        sliderOpts.dotsContainer = $el.data('dots-container')
      }

      // Навигация по hash
      if ($el.attr('data-url-hash-nav')) {
        sliderOpts.URLhashListener = true
        sliderOpts.startPosition = 'URLHash'
      }

      // Начальный слайд определяется по hash
      if ($el.attr('data-hash-start')) {
        const { hash } = window.location

        if (hash) {
          sliderOpts.startPosition = slideNumberByHash($el, hash.slice(1))
        }
      }

      // Начальный слайд (число)
      if ($el.attr('data-start-from')) {
        sliderOpts.startPosition = $el.data('start-from')
      }

      // Если true, то при движении зажатым указателем(drag) по Y на 30px, то при любом X движении слайда не будет
      if ($el.attr('data-better-scroll') && $el.data('better-scroll')) {
        (function () {
          let start = 0

          $el.on('drag.owl.carousel', event => {
            start = event.relatedTarget.drag.startY
          })

          $el.on('dragged.owl.carousel', event => {
            if (Math.abs(start - event.relatedTarget.drag.startY) > 30) {
              $el.one('change.owl.carousel', changeEvent => {
                $el.one('changed.owl.carousel', () => {
                  $el.trigger('to.owl.carousel', [changeEvent.item.index])
                })
              })
            }
          })
        }())
      }

      // в слайдере должно быть чётное количество элементов, если оно нечётное, то добавляется пустой блок
      /**
             * @type {number} - число пустых слайдов, которые необходимо добавить для корректной работы
             */
      const emptyCount = (sliderOpts.slideBy - (itemCount - (sliderOpts.items - sliderOpts.slideBy)) % sliderOpts.slideBy) % sliderOpts.slideBy

      for (let i = 0; i < emptyCount; i++) {
        $el.append($('<div>'))
      }

      $el.on('initialized.owl.carousel', initial => {
        const presentIndex = initial.page.index

        $el.find('.owl-dots').off('click')

        dotsClasses = new Array(initial.page.count)
        for (let i = 0; i < initial.page.count; i++) {
          dotsClasses[i] = classesForDots[3]
        }

        $el.find('.owl-prev').toggle(false)

        if (sliderOpts.nav) {
          updateNav($el, presentIndex, initial.item.count, initial.item.index)
        }

        let i = 0
        let bigDotsLeft = numOfMiddle

        for (; i < initial.page.count && bigDotsLeft > 0; i++) {
          if (i === 0) {
            dotsClasses[presentIndex] = classesForDots[0]
            bigDotsLeft--
          }

          if (i !== 0 && presentIndex + i < initial.page.count) {
            dotsClasses[presentIndex + i] = classesForDots[0]
            bigDotsLeft--
          }

          if (i !== 0 && presentIndex - i >= 0) {
            dotsClasses[presentIndex - i] = classesForDots[0]
            bigDotsLeft--
          }
        }

        if (presentIndex + i < initial.page.count) {
          dotsClasses[presentIndex + i] = classesForDots[1]
        }

        if (presentIndex + i + 1 < initial.page.count) {
          dotsClasses[presentIndex + i + 1] = classesForDots[2]
        }

        if (presentIndex - i >= 0) {
          dotsClasses[presentIndex - i] = classesForDots[1]
        }

        if (presentIndex - i - 1 >= 0) {
          dotsClasses[presentIndex - i - 1] = classesForDots[2]
        }

        // Перманетное скрытие точек
        const truePageCount = (initial.item.count - (sliderOpts.items - sliderOpts.slideBy)) / sliderOpts.slideBy

        for (let i = 0; i < initial.page.count - truePageCount; i++) {
          dotsClasses[initial.page.count - i - 1] = classesForDots[4]
        }

        triggerDataEvents($el)
        renderDots($el, dotsClasses, initial.relatedTarget.settings.dotsContainer)
      })

      $el.on('translated.owl.carousel', event => {
        const presentIndex = event.page.index
        const slidesCount = event.item.count
        const presentSlideIndex = event.item.index

        if (sliderOpts.nav) {
          updateNav($el, presentSlideIndex, slidesCount, sliderOpts.items)
        }

        // поведение точек
        if (dotsClasses[presentIndex] !== classesForDots[0]) {
          let tailRight = false

          if ((presentIndex !== slidesCount - 1 && dotsClasses[presentIndex + 1] === classesForDots[0]) || lastIndex > presentIndex) {
            tailRight = true
          }

          dotsClasses = new Array(slidesCount)
          for (let i = 0; i < slidesCount; i++) {
            dotsClasses[i] = classesForDots[3]
          }

          if (tailRight) {
            for (let i = presentIndex; i < slidesCount && i - presentIndex < numOfMiddle; i++) {
              dotsClasses[i] = classesForDots[0]
            }
            dotsClasses[presentIndex + numOfMiddle] = classesForDots[1]

            if (presentIndex - 1 >= 0) {
              dotsClasses[presentIndex - 1] = classesForDots[1]
            }

            if (presentIndex - 2 >= 0) {
              dotsClasses[presentIndex - 2] = classesForDots[2]
            }

            if (presentIndex + numOfMiddle + 1 < slidesCount) {
              dotsClasses[presentIndex + numOfMiddle + 1] = classesForDots[2]
            }

            if (presentIndex + numOfMiddle + 2 < slidesCount) {
              dotsClasses[presentIndex + numOfMiddle + 2] = classesForDots[3]
            }
          } else {
            for (let i = presentIndex; i >= 0 && presentIndex - i < numOfMiddle; i--) {
              dotsClasses[i] = classesForDots[0]
            }
            dotsClasses[presentIndex - numOfMiddle] = classesForDots[1]

            if (presentIndex + 1 < slidesCount) {
              dotsClasses[presentIndex + 1] = classesForDots[1]
            }

            if (presentIndex + 2 < slidesCount) {
              dotsClasses[presentIndex + 2] = classesForDots[2]
            }

            if (presentIndex - numOfMiddle - 1 >= 0) {
              dotsClasses[presentIndex - numOfMiddle - 1] = classesForDots[2]
            }

            if (presentIndex - numOfMiddle - 2 >= 0) {
              dotsClasses[presentIndex - numOfMiddle - 2] = classesForDots[3]
            }
          }

          dotsClasses[presentIndex] = classesForDots[0]
          lastIndex = presentIndex

          renderDots($el, dotsClasses, event.relatedTarget.settings.dotsContainer)
        }
      })

      if ($el.hasClass('b-owl-slider_part')) {
        $el.owlCarousel(partSliderOpts)
      } else {
        $el.owlCarousel(sliderOpts)
      }
    })
  }

  $(document).trigger('initSlider')
})
