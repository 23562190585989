<template>
  <div class="club-discount-info">
    <div>
      <div class="d-flex align-stretch">
        <div class="club-discount-info__line club-discount-info__line_gradient" />
        <div class="ml-3">
          <div class="ui-text ui-text_subtitle-1">
            {{ priceList.sum }}
          </div>
          <div class="ui-text ui-text_body-2 mt-1">
            оплачиваете при записи на приём невозвратную сумму и получаете купон
          </div>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="club-discount-info__line club-discount-info__line_solid" />
        <div class="ml-3">
          <div class="ui-text ui-text_subtitle-1">
            {{ priceList.lpu }}
          </div>
          <div class="ui-text ui-text_body-2 mt-1">
            останется оплатить в клинике, когда покажете купон администратору
          </div>
        </div>
      </div>
      <div class="mt-4 mb-5">
        <div
          class="ui-text ui-text_body-1 ui-kit-color-primary cursor-pointer"
          @click="handleClickInfoIcon"
        >
          Подробнее про клуб
        </div>
      </div>
    </div>

    <div class="club-discount-info__payment">
      <div class="club-discount-info__payment-content ui-kit-color-text-secondary">
        <div class="ui-text ui-text_body-1 d-flex justify-space-between mb-3">
          <span>
            Обычная цена
          </span>
          <span
            class="ui-kit-color-text"
            data-qa="club_discount_full_price"
          >
            {{ priceList.full }}
          </span>
        </div>
        <div
          v-if="!clubOfferData.cardDateEnd"
          class="ui-text ui-text_body-1 d-flex justify-space-between mb-4"
        >
          <div>
            <div class="d-flex align-center">
              <span class="mr-2">
                Доступ к клубу
              </span>
            </div>
            <div
              v-if="!clubOfferData.cardDateEnd"
              class="ui-text ui-text_body-2 ui-kit-color-text-info"
            >
              6 месяцев
            </div>
          </div>
          <template v-if="!clubOfferData.cardDateEnd">
            <span
              v-if="clubOfferData.cardPrice"
              data-qa="club_discount_card_price ui-kit-color-text"
            >
              {{ priceList.card }}
            </span>
            <template v-else>
              <div class="d-flex align-center">
                <div class="ui-kit-color-text mr-1">
                  в подарок
                </div>
                <img
                  src="/static/_v1/pd/icons/emoji/wrapped-present.png"
                  width="20"
                  height="20"
                  alt="Иконка Завёрнутый подарок"
                  data-qa="club_discount_card_free_icon"
                >
              </div>
            </template>
          </template>
        </div>
        <div class="ui-text ui-text_body-1 d-flex justify-space-between mb-3">
          <span>
            Скидка клуба
          </span>
          <span
            class="ui-text ui-kit-color-error"
            data-qa="club_discount_profit"
          >
            -{{ priceList.profit }}
          </span>
        </div>
        <div class="ui-text ui-text_h6 ui-kit-color-text d-flex justify-space-between">
          <span>
            Итого
          </span>
          <span data-qa="club_discount_total_price">
            {{ priceList.total }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dispatchCustomEventGlobally, getFormattedPrice } from 'utils'
import { CLUB_EVENTS } from 'components/common/Club/constants'
import { getDefaultClubOfferDataPropInterface } from 'components/common/Club/components/core/ClubDiscount/models'

export default {
  name: 'ClubDiscountInfo',
  props: {
    fullPrice: {
      type: Number,
      required: true,
    },
    clubOfferData: {
      type: Object,
      default: getDefaultClubOfferDataPropInterface,
    },
  },
  computed: {
    priceList() {
      return {
        full: getFormattedPrice(this.fullPrice),
        lpu: getFormattedPrice(this.clubOfferData.lpuPrice),
        coupon: getFormattedPrice(this.clubOfferData.couponPrice),
        card: getFormattedPrice(this.clubOfferData.cardPrice),
        total: getFormattedPrice(this.clubOfferData.totalPrice),
        sum: getFormattedPrice(this.clubOfferData.couponPrice + this.clubOfferData.cardPrice),
        profit: getFormattedPrice(
          this.fullPrice - (this.clubOfferData.totalPrice - this.clubOfferData.cardPrice),
        ),
      }
    },
  },
  methods: {
    handleClickInfoIcon() {
      dispatchCustomEventGlobally(CLUB_EVENTS.clubSelectInfoModal)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.club-discount-info {
  &__payment {
    padding: 1px;
    border-radius: $border-radius-md;
    background-image: $club-coral-linear-gradient;
  }

  &__payment-content {
    padding: 20px;
    border-radius: inherit;
    background-color: $ui-kit-bg-gray-0;
  }

  &__line {
    border-radius: $border-radius-sm;
    min-width: 8px;
    width: 8px;
    height: initial;

    &_gradient {
      background-color: $ui-kit-text-warning;
      background-image: $club-coral-double-radial-gradient;
    }

    &_solid {
      background-color: $ui-kit-bg-club;
    }
  }
}
</style>
